import styled from 'styled-components';
import {
  space,
  width,
  style,
  color,
  height,
  border,
  position,
  fontSize,
  minWidth,
  minHeight,
  maxHeight,
  overflowY,
} from 'styled-system';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// ui
import { Box } from './box';
//////////////////////////////////////////////////

export const Wrapper = styled.div`
  z-index: 12;
  position: relative;

  display: ${({ display }: Object) => display || 'flex'};
`;

export const FooterWrapper = styled.div`
  ${space}

  width: 100%;
  display: flex;
  justify-content: center;
`;

export const RootWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  position: absolute;
  overflow-x: hidden;
`;

export const LayoutWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1300;
  position: fixed;
`;

export const PageWrapper = styled.div`
  ${width}
  ${space}

  position: relative;

  z-index: ${({ zi }: Object) => zi};
  max-height: ${({ maxHeight }: Object) => maxHeight};
  min-height: ${({ minHeight }: Object) => minHeight};
  height: ${({ height }: Object) => height || '100vh'};
  overflow: ${({ overflow }: Object) => overflow || 'auto'};
  padding-left: ${({ pl }: Object) => `${pl || GC.PAGE_WRAPPER_PADDING_LEFT}px`};
  padding-bottom: ${({ pb }: Object) => `${pb || GC.PAGE_WRAPPER_PADDING_BOTTOM}px`};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('colors.light.mainLight')};

  &::-webkit-scrollbar-thumb
  {
    background-color: ${() => G.getTheme('scroll.bgColor')};
  }
`;

export const ListWrapper = styled(PageWrapper)`
  padding-bottom: 0;
`;

export const PageHeaderWrapper = styled.div`
  ${space}
  ${width}
  ${height}
  ${overflowY}

  display: flex;

  top: ${({ top }: Object) => top};
  left: ${({ left }: Object) => left};
  z-index: ${({ zI }: Object) => zI || 'auto'};
  box-shadow: ${({ boxShadow }: Object) => boxShadow};
  justify-content: ${({ justify }: Object) => justify};
  align-items: ${({ aI }: Object) => aI || 'flex-end'};
  background-color: ${({ bgColor }: Object) => bgColor};
  position: ${({ position }: Object) => position || 'static'};

  &::-webkit-scrollbar { height: 3px };
`;

PageHeaderWrapper.defaultProps = {
  pt: 15,
  height: GC.PAGE_HEADER_WRAPPER_HEIGHT,
};

export const ListsPagesHeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  padding: 10px;
  align-items: center;
  margin-bottom: 15px;

  color: ${() => G.getTheme('pages.listsPages.header.textColor')};
  background-color: ${() => G.getTheme('pages.listsPages.header.bgColor')};
  border-color: ${() => G.getTheme('pages.listsPages.header.borderColor')};
`;

export const SimpleWrapper = styled.div`
  ${space}
  ${width}
  ${height}
  ${border}
  ${minWidth}
  ${minHeight}
  ${maxHeight}

  z-index: ${({ zi }: Object) => zi};
  color: ${({ color }: Object) => color};
  justify-content: ${({ jc }: Object) => jc};
  overflow:  ${({ overflow }: Object) => overflow};
  display: ${({ display }: Object) => display || 'flex'};
  flex-direction: ${({ flexDirection }: Object) => flexDirection};
  align-items: ${({ alignItems }: Object) => alignItems || 'center'};
`;

export const RelativeWrapper = styled.div`
  ${width}
  ${space}

  position: relative;

  display: ${({ display }: Object) => display || 'flex'};
`;

export const AbsoluteWrapper = styled.div`
  ${width}
  ${space}

  position: absolute;

  top: ${({ top }: Object) => top || 'initial'};
  left: ${({ left }: Object) => left || 'initial'};
  right: ${({ right }: Object) => right || 'initial'};
  z-index: ${({ zIndex }: Object) => zIndex || 'auto'};
  bottom: ${({ bottom }: Object) => bottom || 'initial'};
  display: ${({ display }: Object) => display || 'flex'};
`;

export const PageTitleWrapper = styled.div`
  ${width}
  ${height}

  display: flex;
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
  position: relative;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  padding: ${({ p }: Object) => p || '0px 15px'};
  margin: ${({ m }: Object) => m || '15px 15px 0 0'};
  color: ${() => G.getTheme('pages.listTitle.color')};
  background-color: ${() => G.getTheme('colors.dark.blue')};
`;

PageTitleWrapper.defaultProps = {
  height: 45,
};

export const PageTitleCount = styled.div`
  ${width}
  ${height}
  ${fontSize}

  color: white;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  padding: ${({ p }: Object) => p || 0};
  top: ${({ t }: Object) => t || '-15px'};
  left: ${({ l }: Object) => l || 'unset'};
  right: ${({ r }: Object) => r || '-15px'};
  border-radius: ${({ br }: Object) => br || '50%'};
  border: 2px solid ${() => G.getTheme('pages.listTitle.countBorderColor')};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('colors.dark.blue')};
`;

PageTitleCount.defaultProps = {
  width: 30,
  height: 30,
  fontSize: 16,
};

export const IconWrapper = styled.div`
  ${space}
  ${width}
  ${height}

  cursor: ${({ cursor }: Object) => cursor};
`;

export const IconWrapperPositioned = styled(IconWrapper)`
  position: ${({ position }: Object) => position};
`;

export const ZOrderWrapper = styled.div`
  ${space}

  z-index: ${({ zIndex }: Object) => zIndex || 'auto'};
  position: ${({ position }: Object) => position || 'relative'};
`;

export const StickedWrapper = styled.div`
  ${space}
  ${width}
  ${height}
  ${position}
  ${minWidth}

  background-color: ${({ bgColor }: Object) => bgColor};
`;

StickedWrapper.defaultProps = {
  // TODO: remove after testing
  // zIndex: 12,
  height: 'unset',
  position: 'sticky',
};

// demo last
export const textTransform = style({
  key: 'textTransform',
  prop: 'textTransform',
  cssProp: 'textTransform',
  transformValue: (n: string) => n,
});

export const Text = styled.div`
  ${width}
  ${space}
  ${color}
  ${fontSize}
  ${textTransform}

  cursor: ${({ cursor }: Object) => cursor};
  font-weight: ${({ fontWeight }: Object) => fontWeight};
  word-break: ${({ wordBreak }: Object) => wordBreak || 'break-all'};

  &:hover {
    color: ${({ hoverColor }: Object) => hoverColor};
    cursor: ${({ hoverCursor }: Object) => hoverCursor};
  }
`;

export const EditorWrapper = styled(Box)`
  border-radius: 4px;
  border: 1px solid ${({ hasError }: Object) => G.ifElse(
    hasError,
    G.getTheme('forms.inputs.borderColorErr'),
    G.getTheme('colors.dark.grey'),
  )};

  & .DraftEditor-root {
    z-index: 9;
    padding: 0 15px;
  }

  & .rdw-editor-toolbar {
    background-color: ${() => G.getTheme('colors.light.lightGrey')};
  }

  & .rdw-emoji-modal, .rdw-link-modal {
    right: 0;
    left: auto;
  }

  & .rdw-emoji-modal {
    width: 215px;
  }

  & .rdw-link-modal {
    height: 230px;
  }

  & .rdw-link-decorator-wrapper a > span > span, .public-DraftStyleDefault-block > span > span {
    font-family: inherit;
  }
`;
