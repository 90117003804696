import * as R from 'ramda';
import React from 'react';
import smartlookClient from 'smartlook-client';
import { put, all, call, select, takeLatest } from 'redux-saga/effects';
// common
import * as A from '../common/actions';
import { makeSelectMailIntegrationType, makeSelectConnectedToMailIntegrationEmails } from '../common/selectors';
// components
import RequestStatusModal from '../components/request-status-modal';
import { openModal, closeModal } from '../components/modal/actions';
import { openLoader, closeLoader } from '../components/loader/actions';
// features
import { makeSelectCurrentUserData } from '../features/auth/selectors';
import { receivedSwitchBranchSuccess } from '../features/branch/actions';
import { makeSelectCurrentBranchGuid } from '../features/branch/selectors';
import { getExternalSystemListRequest } from '../features/dispatch-details-new/load/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// sagas
import { crudSaga, openWindowSaga } from './common';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

function* handleExportTelToLoadBoardSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { rePost } = payload;

    const method = G.ifElse(rePost, 'put', 'post');
    const endpoint = G.ifElse(rePost, endpointsMap.postedShipment, endpointsMap.postedShipmentBulk);
    const partialSuccessMsg = G.getWindowLocale('messages:partial-success', 'Partial Success');

    const requestData = G.ifElse(
      rePost,
      R.path(['data', 'postedShipments', 0], payload),
      payload.data,
    );

    const successMsg = G.ifElse(
      G.isTrue(rePost),
      G.getWindowLocale('messages:load-re-post', 'The Load Posting was updated successfully'),
      G.getWindowLocale('messages:load-post', 'The Load Posting to the Load Board was successful'),
    );

    const res = yield call(sendRequest, method, endpoint, { data: requestData });

    const { data, status } = res;

    const checkData = {
      errors: data.errors,
      success: R.of(Array, successMsg),
    };

    if (G.isResponseSuccess(status, checkData, successMsg, partialSuccessMsg)) {
      const successData = G.ifElse(
        rePost,
        { elements: R.of(Array, data) },
        data,
      );

      yield put(A.updateTelOnLoadBoardSuccess(successData));
    } else {
      yield call(G.handleFailResponse, res, 'handleExportTelToLoadBoardSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportTelToLoadBoardSaga exception');
  }
}

function* handleDeletePostedShipmentStateSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, objectGuid } = payload;

    const res = yield call(sendRequest, 'delete', endpointsMap.postedShipmentStateById(guid));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deletePostedShipmentStateSuccess({ guid, objectGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeletePostedShipmentStateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeletePostedShipmentStateSaga exception');
  }
}

function* handleUpdatePostedShipmentStateSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, objectGuid } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.postedShipmentStateRepost(guid));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.updatePostedShipmentStateSuccess({ data, objectGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdatePostedShipmentStateSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdatePostedShipmentStateSaga exception');
  }
}

function* handleAddPostedShipmentStateSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { postedShipmentGuid } = payload;

    const res = yield call(sendRequest, 'post', endpointsMap.postedShipmentState, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.addPostedShipmentStateSuccess({ data, postedShipmentGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdatePostedShipmentStateSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdatePostedShipmentStateSaga exception');
  }
}

function* callStatusCheckTelRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.callStatusCheck, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'callStatusCheckTelRequest');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'callStatusCheckTelRequest');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* createQBIIFImportByInvoiceTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { type, guids, callback } = payload;
    const qbiifEndpointsMap = {
      driver: endpointsMap.driverInvoiceExportIIF,
      carrier: endpointsMap.carrierInvoiceExportIIF,
      customer: endpointsMap.customerInvoiceExportIIF,
      vendorPayroll: endpointsMap.vendorPayrollExportIIF,
      vendor: endpointsMap.telFleetVendorInvoiceExportIIF,
      serviceVendor: endpointsMap.serviceVendorInvoiceExportIIF,
    };
    const endpoint = G.getPropFromObject(type, qbiifEndpointsMap);
    const arr = ['carrier', 'customer', 'serviceVendor'];
    const options = {
      resType: 'arraybuffer',
      data: G.ifElse(R.includes(type, arr), R.dissoc(GC.FIELD_TYPE, payload), guids),
    };
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);
      G.callFunction(callback);
    } else {
      yield call(
        G.handleFailResponse,
        G.convertArrayBufferFailResponse(res),
        'createQBIIFImportByInvoiceTypeRequest fail',
      );
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createQBIIFImportByInvoiceTypeRequest exception');
  }
}

function* handleCreateLoadDocumentsSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { data, loadType, callback, refreshCallback } = payload;

    const endpoint = G.ifElse(
      G.isLoadTypeClo(loadType),
      endpointsMap.cloDocument,
      endpointsMap.telDocument,
    );

    const res = yield all(data.map((fileData: Object) => {
      const options = { data: G.makeDataForDocument(fileData) };

      return call(sendRequest, 'post', endpoint, options);
    }));

    const isSuccess = R.all(({ status }: Object) => G.isResponseSuccess(status), res);

    if (isSuccess) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');

      yield put(closeModal());
    } else {
      const errors = R.map(
        ({ data, status }: Object) => {
          if (G.isResponseSuccess(status)) {
            return null;
          }

          if (R.equals(status, 500)) {
            return G.getWindowLocale(
              'messages:error:500',
              'Oops! The system is experiencing a problem. The issue has been reported.',
            );
          }

          const { invalidFields, message, errors, error_description } = data;

          let errorMessage;

          if (G.isNotNil(invalidFields)) {
            errorMessage = R.compose(
              R.join('; '),
              R.map(({ message, fieldName }: Object) => `${fieldName}: ${message}`),
            )(invalidFields);
          } else if (G.isNotNil(message)) {
            if (G.isNotNilAndNotEmpty(errors)) {
              errorMessage = R.join('; ', errors);
            } else {
              errorMessage = message;
            }
          } else if (G.isNotNil(error_description)) {
            errorMessage = error_description; // eslint-disable-line
          }

          return errorMessage;
        },
        res,
      );

      G.callFunctionWithArgs(callback, errors);

      yield all(res.map((item: Object) => call(G.handleFailResponse, item, 'handleCreateLoadDocumentsSaga fail')));
    }

    G.callFunction(refreshCallback);

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateLoadDocumentsSaga exception');
  }
}

function* openDocumentSaga({ documentOptions, downloadDocumentEndpoint }: Object) {
  try {
    const options = {
      resType: 'arraybuffer',
      params: documentOptions,
    };

    const res = yield call(sendRequest, 'get', downloadDocumentEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const file = new window.Blob([data], { type: 'application/pdf' });

      G.openFile(file);

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'openDocumentSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'openDocumentSaga exception');
  }
}

function* printDocumentSaga({ endpoint, options, loadType }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const primaryObjectGuid = R.prop(GC.FIELD_PRIMARY_OBJECT_GUID, data);

      const documentOptions = {
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: R.prop(GC.FIELD_DOCUMENT_FILE_NAME, data),
      };

      const downloadDocumentEndpoint = G.ifElse(
        R.equals(loadType, GC.LOAD_TYPE_TEL),
        endpointsMap.telDocumentDownloadFile,
        endpointsMap.cloDocumentDownloadFile,
      );

      yield call(openDocumentSaga, { documentOptions, downloadDocumentEndpoint });
    } else {
      yield call(G.handleFailResponse, res, 'printDocumentSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printDocumentSaga exception');
  }
}

function* handleCreatePrintLoadDocumentsSaga({ payload }: Object) {
  try {
    const { loadGuid, fileName, documents, documentType } = payload;

    const options = {
      data: {
        loadGuid,
        fileName,
        documentType,
        format: 'pdf',
        documentsGuids: documents,
      },
    };

    const endpoint = endpointsMap.telExportDocuments;

    yield call(printDocumentSaga, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL });
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreatePrintLoadDocumentsSaga exception');
  }
}

function* sendTelToMacroPointRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guids, callback } = payload;
    const options = { data: guids };
    const res = yield call(sendRequest, 'post', endpointsMap.sendToMacroPoint, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data)) {
        const telTxtLocale = G.getWindowLocale('titles:tel', 'TEL');
        yield all(data.map(({ errors, primaryReferenceValue }: Object) => {
          const errorsString = R.join(', ', errors);
          const errorMessage = `${telTxtLocale} ${primaryReferenceValue}: ${errorsString}`;

          return call(G.showToastrMessage, 'error', errorMessage);
        }));
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
      G.callFunction(callback);
    } else {
      yield call(G.handleFailResponse, res, 'sendTelToMacroPointRequest');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendTelToMacroPointRequest exception');
  }
}

function* macroPointStopOrderRequest({ payload }: Object) {
  try {
    const { callback, telGuid } = payload;
    yield put(openLoader());
    const options = {
      params: { telGuid },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.macroPointStopOrder, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunction(callback);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'sendTelToMacroPointRequest');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendTelToMacroPointRequest exception');
  }
}

function* hideListIssuesByListNameRequest({ payload }: Object) {
  try {
    const { callback, elements, listName } = payload;

    yield put(openLoader());
    const endpoints = {
      carrier: endpointsMap.carrierHideIssues,
      [GC.FLEET_TRUCK_REPORT]: endpointsMap.truckHideIssues,
      [GC.FLEET_DRIVER_REPORT]: endpointsMap.driverHideIssues,
      [GC.FLEET_TRAILER_REPORT]: endpointsMap.trailerHideIssues,
    };
    const endpoint = G.getPropFromObject(listName, endpoints);
    const options = {
      data: { elements },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunction(callback);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'hideListIssuesByListNameRequest');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'hideListIssuesByListNameRequest exception');
  }
}

function* toggleMarkAsHotLoadByLoadTypeRequest({ payload }: Object) {
  try {
    const { hot, guid } = payload;

    yield put(openLoader());
    const loadType = R.toLower(G.getPropFromObject(GC.FIELD_LOAD_TYPE, payload));
    const endpoint = G.ifElse(
      hot,
      endpointsMap.markAsHotLoadByLoadType,
      endpointsMap.removeHotMarkFromLoadByLoadType,
    )(guid, loadType);
    const res = yield call(sendRequest, 'put', endpoint);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.toggleMarkAsHotLoadByLoadTypeSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'toggleMarkAsHotLoadByLoadTypeRequest');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'toggleMarkAsHotLoadByLoadTypeRequest exception');
  }
}

function* toggleMarkAsFastLoadByLoadTypeRequest({ payload }: Object) {
  try {
    const { guid, fastLoad } = payload;

    yield put(openLoader());
    const loadType = R.toLower(G.getPropFromObject(GC.FIELD_LOAD_TYPE, payload));
    const endpoint = G.ifElse(
      fastLoad,
      endpointsMap.markAsFastLoadByLoadType,
      endpointsMap.removeFastMarkFromLoadByLoadType,
    )(guid, loadType);
    const res = yield call(sendRequest, 'put', endpoint);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.toggleMarkAsFastLoadByLoadTypeSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'toggleMarkAsFastLoadByLoadTypeRequest');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'toggleMarkAsFastLoadByLoadTypeRequest exception');
  }
}

function* getUserMonitoringIntegrationUseListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.userMonitoringConfigListForUse,
      parentSagaName: 'getUserMonitoringIntegrationUseListRequest',
      successAction: A.receivedUserMonitoringIntegrationUseListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getUserMonitoringIntegrationUseListRequest exception');
  }
}

function* smartlookIntegrationSaga({ payload }: Object) {
  try {
    const projectKey = R.compose(
      R.path([GC.FIELD_PROJECT_KEY]),
      R.find(R.propEq(GC.USER_MONITORING_APP_INTEGRATION_TYPE_SMARTLOOK, GC.FIELD_USER_MONITORING_APP)),
    )(R.or(payload, []));

    if (G.isNilOrEmpty(projectKey)) {
      const init = smartlookClient.initialized();

      if (G.isTrue(init)) return smartlookClient.disable();

      return;
    }

    smartlookClient.init(projectKey);
    const currentUser = yield select(makeSelectCurrentUserData());
    const { login_id, user_guid } = currentUser;
    const res = yield call(sendRequest, 'get', endpointsMap.getUserWithProfileEndpoint(user_guid));
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { fullText } = G.getUserInfo(data);
      smartlookClient.identify(login_id, {
        name: fullText,
      });
    } else {
      yield call(G.handleFailResponse, res, 'smartlookIntegrationSaga');
    }
  } catch (error) {
    yield call(G.handleException, error, 'smartLookUserIdentifySaga');
  }
}

function* changeSalesOrAgentsRequest({ payload }: Object) {
  try {
    const { loadType, loadGuid, fromPage, tableIndex, commissionAssignments } = payload;

    yield put(openLoader());
    const options = {
      data: { loadGuid, commissionAssignments },
    };
    const endpoint = G.ifElse(
      G.isLoadTypeTel(loadType),
      endpointsMap.changeBrokerAgents,
      endpointsMap.changeSalePersons,
    );
    const res = yield call(sendRequest, 'put', endpoint, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.changeSalesOrAgentsSuccess({ data, loadType, loadGuid, fromPage, tableIndex }));
    } else {
      yield call(G.handleFailResponse, res, 'changeSalesOrAgentsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeSalesOrAgentsRequest exception');
  }
}

function* sendTelToExternalSystemRequest({ payload }: Object) {
  try {
    const { telGuid, fromPage, integrationType } = payload;

    yield put(openLoader());
    const options = {
      params: { telGuid, integrationType },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.sendToExternalSystem, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (R.equals(fromPage, 'details')) {
        yield put(getExternalSystemListRequest({ [GC.FIELD_LOAD_GUID]: telGuid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'sendTelToExternalSystemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendTelToExternalSystemRequest exception');
  }
}

function* sendInvoiceToBCByInvoiceTypeRequest({ payload }: Object) {
  try {
    const { callback, invoiceType } = payload;

    yield put(openLoader());

    const options = {
      data: R.compose(
        R.assoc('separateInvoices', false),
        R.omit(['callback', 'invoiceType']),
      )(payload),
    };

    const accountingEndpointsMap = {
      vendorInvoice: endpointsMap.sendVendorInvoiceListToBC,
      carrierInvoice: endpointsMap.sendCarrierInvoiceListToBC,
      customerInvoice: endpointsMap.sendCustomerInvoiceListToBC,
      serviceVendor: endpointsMap.sendServiceVendorInvoiceListToBC,
    };

    const endpoint = G.getPropFromObject(invoiceType, accountingEndpointsMap);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunction(callback);
      yield call(G.showToastrMessage, 'success', 'messages:request-processing');
    } else {
      yield call(G.handleFailResponse, res, 'sendInvoiceToBCByInvoiceTypeRequest fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendInvoiceToBCByInvoiceTypeRequest exception');
  }
}

function* sendInvoiceToSageByInvoiceTypeRequest({ payload }: Object) {
  try {
    const { callback, invoiceType, notCloseModal } = payload;

    yield put(openLoader());

    const options = {
      data: R.compose(
        R.assoc('separateInvoices', false),
        R.omit(['callback', 'invoiceType']),
      )(payload),
    };

    const accountingEndpointsMap = {
      vendorInvoice: endpointsMap.sendVendorInvoiceListToSageIntacct,
      vendorPayroll: endpointsMap.sendVendorPayrollListToSageIntacct,
      carrierInvoice: endpointsMap.sendCarrierInvoiceListToSageIntacct,
      customerInvoice: endpointsMap.sendCustomerInvoiceListToSageIntacct,
      serviceVendor: endpointsMap.sendServiceVendorInvoiceListToSageIntacct,
    };

    const endpoint = G.getPropFromObject(invoiceType, accountingEndpointsMap);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunction(callback);

      if (G.isNotNil(data.errors)) {
        if (G.isNotNilAndNotEmpty(data.errors)) {
          G.handlePartialSuccessErrors(data.errors);
        } else {
          yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
        }
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:request-processing');
      }
    } else {
      yield call(G.handleFailResponse, res, 'sendInvoiceToSageByInvoiceTypeRequest fail');
    }

    if (G.isTrue(notCloseModal)) return yield put(closeLoader());

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendInvoiceToSageByInvoiceTypeRequest exception');
  }
}

function* massCreateCarrierRateSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    yield call(crudSaga, {
      method: 'post',
      shouldCloseModal: true,
      showSuccessMessage: true,
      options: { data: payload },
      successMessage: 'messages:success:200-201',
      parentSagaName: 'massCreateCarrierRateSaga',
      endpoint: endpointsMap.telCarrierRateMassCreate,
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
  }
}

function* exportInvoicesToEDIRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.CURRENT_BRANCH, R.pathOr(branchGuid, [GC.CURRENT_BRANCH], payload), payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.exportCustomerInvoicesToEdi, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:request-processing');
    } else {
      yield call(G.handleFailResponse, res, 'exportInvoicesToEDIRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'exportInvoicesToEDIRequest exception');
  }
}

function* getConnectedToIntegrationEmailRequest({ payload }: Object = {}) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.FIELD_EMAIL]: payload, [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.isConnectedToMail, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const connectedMails = yield select(makeSelectConnectedToMailIntegrationEmails());

      yield put(A.setConnectedToMailIntegrationEmails(R.assoc(payload, R.head(data), connectedMails)));
    } else {
      yield call(G.handleException('error', 'getConnectedToIntegrationEmailRequest fail'));
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConnectedToIntegrationEmailRequest exception');
  }
}

function* connectToMailIntegrationRequest({ payload }: Object) {
  try {
    const { email, userGuid } = payload;

    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const integrationType = yield select(makeSelectMailIntegrationType());
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };

    const endpoint = G.ifElse(
      G.isNotNilAndNotEmpty(userGuid),
      endpointsMap.connectToMailForUser,
      endpointsMap.connectToMailForBranch,
    );

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(integrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM)) {
        yield put(closeModal());
        yield call(getConnectedToIntegrationEmailRequest, { payload: email });
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      } else {
        yield call(openWindowSaga, { url: data, name: 'mailIntegrationConnect', reloadLocation: true });
      }
    } else {
      yield call(G.handleFailResponse, res, 'connectToMailIntegrationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'connectToMailIntegrationRequest exception');
  }
}

function* disconnectFromMailIntegrationRequest({ payload }: Object) {
  try {
    const { guid, email } = payload;

    yield put(openLoader());

    const endpoint = endpointsMap.disconnectFromMailIntegration(guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.disconnectFromMailIntegrationSuccess(email));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'disconnectFromMailIntegrationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'disconnectFromMailIntegrationRequest exception');
  }
}

export function* getConnectionToMailIntegrationRequiredRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.mailServiceIntegrationConnectConnectionRequired, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setConnectionToMailIntegrationRequired(data));
    } else {
      yield call(G.handleException, 'error', 'getConnectionToMailIntegrationRequiredRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getConnectionToMailIntegrationRequiredRequest exception'));
  }
}

export function* getMailServiceIntegrationConfigAvailableIntegrationTypeRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.getMailServiceIntegrationConfigAvailableIntegrationType,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setMailServiceIntegrationType(data));
    } else {
      yield call(G.handleException, 'error', 'getMailServiceIntegrationConfigAvailableIntegrationTypeRequest fail');
    }
  } catch (error) {
    yield call(G.handleException(error, 'getMailServiceIntegrationConfigAvailableIntegrationTypeRequest exception'));
  }
}

function* sendQuoteRequests({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.CURRENT_BRANCH, currentBranchGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.sendQuoteRequests, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        yield all(R.map(
          ({ errorMessage, primaryReference }: Object) => (
            call(G.showToastrMessage, 'error', `${errorMessage}: ${primaryReference}`)
          ),
          data.errors,
        ));
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'sendQuoteRequests fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'sendQuoteRequests exception'));
  }
}

function* showRequestStatusModal({ payload }: Object) {
  try {
    const { title, status, errors, totalCount, successCount } = payload;

    const requestStatusInfo = `${G.getWindowLocale('titles:exported', 'Exported')
      } ${R.or(successCount, 0)}/${R.or(totalCount, 0)}`;
    const component = (
      <RequestStatusModal
        title={title}
        status={status}
        errors={errors}
        requestStatusInfo={requestStatusInfo}
      />
    );
    const modal = {
      p: '0',
      component,
      options: {
        width: 600,
        height: 'auto',
        overflow: 'auto',
        maxHeight: '90vh',
        outsideCloseButton: true,
      },
    };
    yield put(openModal(modal));
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'showRequestStatusModal exception'));
  }
}

function* getTelIntegrationDocumentsRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      params: { [GC.FIELD_TEL_GUID]: payload },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.getTelVendorDocuments, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'getTelIntegrationDocumentsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException(error, 'getTelIntegrationDocumentsRequest exception'));
  }
}

function* getShowDriverOnboardingTabRequest() {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: currentBranchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.driverOnboardingIntegrationConfigShowReportTab, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getShowDriverOnboardingTabSuccess(data));
    } else {
      yield call(G.handleException('error', 'getShowDriverOnboardingTabRequest exception'));
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException(error, 'getShowDriverOnboardingTabRequest exception'));
  }
}

export function* GlobalActionsWatcherSaga() {
  yield takeLatest(A.sendQuoteRequests, sendQuoteRequests);
  yield takeLatest(A.showRequestStatusModal, showRequestStatusModal);
  yield takeLatest(A.addCarrierRatesRequest, massCreateCarrierRateSaga);
  yield takeLatest(A.callStatusCheckTelRequest, callStatusCheckTelRequest);
  yield takeLatest(A.exportTelToLoadBoard, handleExportTelToLoadBoardSaga);
  yield takeLatest(A.changeSalesOrAgentsRequest, changeSalesOrAgentsRequest);
  yield takeLatest(A.macroPointStopOrderRequest, macroPointStopOrderRequest);
  yield takeLatest(A.exportInvoicesToEDIRequest, exportInvoicesToEDIRequest);
  yield takeLatest(A.sendTelToMacroPointRequest, sendTelToMacroPointRequest);
  yield takeLatest(A.createLoadDocumentsRequest, handleCreateLoadDocumentsSaga);
  yield takeLatest(A.sendTelToExternalSystemRequest, sendTelToExternalSystemRequest);
  yield takeLatest(A.addPostedShipmentStateRequest, handleAddPostedShipmentStateSaga);
  yield takeLatest(A.connectToMailIntegrationRequest, connectToMailIntegrationRequest);
  yield takeLatest(A.hideListIssuesByListNameRequest, hideListIssuesByListNameRequest);
  yield takeLatest(A.createPrintLoadDocumentsRequest, handleCreatePrintLoadDocumentsSaga);
  yield takeLatest(A.getShowDriverOnboardingTabRequest, getShowDriverOnboardingTabRequest);
  yield takeLatest(A.getTelIntegrationDocumentsRequest, getTelIntegrationDocumentsRequest);
  yield takeLatest(A.updatePostedShipmentStateRequest, handleUpdatePostedShipmentStateSaga);
  yield takeLatest(A.deletePostedShipmentStateRequest, handleDeletePostedShipmentStateSaga);
  yield takeLatest(receivedSwitchBranchSuccess, getConnectionToMailIntegrationRequiredRequest);
  yield takeLatest(A.sendInvoiceToBCByInvoiceTypeRequest, sendInvoiceToBCByInvoiceTypeRequest);
  yield takeLatest(A.receivedUserMonitoringIntegrationUseListSuccess, smartlookIntegrationSaga);
  yield takeLatest(A.disconnectFromMailIntegrationRequest, disconnectFromMailIntegrationRequest);
  yield takeLatest(A.toggleMarkAsHotLoadByLoadTypeRequest, toggleMarkAsHotLoadByLoadTypeRequest);
  yield takeLatest(A.toggleMarkAsFastLoadByLoadTypeRequest, toggleMarkAsFastLoadByLoadTypeRequest);
  yield takeLatest(A.createQBIIFImportByInvoiceTypeRequest, createQBIIFImportByInvoiceTypeRequest);
  yield takeLatest(A.sendInvoiceToSageByInvoiceTypeRequest, sendInvoiceToSageByInvoiceTypeRequest);
  yield takeLatest(A.getUserMonitoringIntegrationUseListRequest, getUserMonitoringIntegrationUseListRequest);
  yield takeLatest(receivedSwitchBranchSuccess, getMailServiceIntegrationConfigAvailableIntegrationTypeRequest);
}

export default GlobalActionsWatcherSaga;
