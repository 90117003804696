import * as R from 'ramda';
// helpers/constants
import * as GC from '../constants';
import { getWindowLocale } from '../helpers/locale';
import { isTrue, ifElse, isQuickOrderEntry } from '../helpers';
//////////////////////////////////////////////////

const replaceKeysToParams = (options: string | Object, endpoint: string) => {
  if (R.is(String, options)) {
    return R.replace(/:[^/]*/, options, endpoint);
  } else if (R.is(Object, options)) {
    let newEndpoint = endpoint;

    R.forEachObjIndexed((item: string, key: string) => {
      const regKey = new RegExp(`:${key}`, 'g');
      newEndpoint = R.replace(regKey, item, newEndpoint);
    }, options);

    return newEndpoint;
  }

  return endpoint;
};

const routesMap = {
  homePage: GC.ROUTE_PATH_HOME,
  loginPage: GC.ROUTE_PATH_LOGIN,
  profile: GC.ROUTE_PATH_PROFILE,
  oauthPage: GC.ROUTE_PATH_OAUTH2_POPUP,
  // ANALYTIC ROUTES
  dashboards: GC.ROUTE_PATH_DASHBOARDS,
  analyticMaps: GC.ROUTE_PATH_FLEET_MAP,
  analyticsDashboard: GC.ROUTE_PATH_ANALYTICS,
  // BRANCH ROUTES
  newBranch: GC.ROUTE_PATH_BRANCH_NEW,
  branchList: GC.ROUTE_PATH_BRANCH_LIST,
  getEditBranchRoute: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_BRANCH_EDIT),
  // CARRIER ROUTES
  carrierList: GC.ROUTE_PATH_CARRIERS_LIST,
  carrierResponse: GC.ROUTE_PATH_CARRIERS_RESPONSE,
  carrierRatingReport: GC.ROUTE_PATH_CARRIER_RATING_REPORT,
  carrierEdit: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_CARRIER_EDIT),
  carrierProfile: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_CARRIER_PROFILE),
  // service vendor routes
  serviceVendorList: GC.ROUTE_PATH_SERVICE_VENDOR_LIST,
  editServiceVendor: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_SERVICE_VENDOR_EDIT),
  // CHART ROUTES
  chartNew: GC.ROUTE_PATH_CHARTS_NEW,
  chartList: GC.ROUTE_PATH_CHARTS_LIST,
  chartEdit: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_CHARTS_EDIT),
  // CLO ROUTES
  cloList: GC.ROUTE_PATH_CLO_REPORT_LIST,
  // CONFIGURATION ROUTES
  configUI: GC.ROUTE_PATH_CONFIG_UI,
  configTEL: GC.ROUTE_PATH_CONFIG_TEL,
  configCLO: GC.ROUTE_PATH_CONFIG_CLO,
  configAudit: GC.ROUTE_PATH_CONFIG_AUDIT,
  configTruck: GC.ROUTE_PATH_CONFIG_TRUCK,
  configRating: GC.ROUTE_PATH_CONFIG_RATING,
  configDriver: GC.ROUTE_PATH_CONFIG_DRIVER,
  configTrailer: GC.ROUTE_PATH_CONFIG_TRAILER,
  configGeneral: GC.ROUTE_PATH_CONFIG_GENERAL,
  configCarrier: GC.ROUTE_PATH_CONFIG_CARRIER,
  configOptional: GC.ROUTE_PATH_CONFIG_OPTIONAL,
  configPassword: GC.ROUTE_PATH_CONFIG_PASSWORD,
  configInvoices: GC.ROUTE_PATH_CONFIG_INVOICES,
  configTemplates: GC.ROUTE_PATH_CONFIG_TEMPLATES,
  configMobileApp: GC.ROUTE_PATH_CONFIG_MOBILEAPP,
  configIntegration: GC.ROUTE_PATH_CONFIG_INTEGRATION,
  configFleetGeneral: GC.ROUTE_PATH_CONFIG_FLEET_GENERAL,
  configCommunication: GC.ROUTE_PATH_CONFIG_COMMUNICATION,
  configServiceVendor: GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR,
  configTaskManagement: GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT,
  configClaimManagement: GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT,
  // EXTERNAL LOAD BOARD ROUTES
  externalloadboard: GC.ROUTE_PATH_LOAD_BOARD,
  // FLEET ROUTES
  driversCard: GC.ROUTE_PATH_DRIVERS_CARD,
  fleetTruckList: GC.ROUTE_PATH_FLEET_TRUCKS_LIST,
  fleetVendorList: GC.ROUTE_PATH_FLEET_VENDOR_LIST,
  fleetDriverList: GC.ROUTE_PATH_FLEET_DRIVERS_LIST,
  fleetTrailerList: GC.ROUTE_PATH_FLEET_TRAILERS_LIST,
  availableDrivers: GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST,
  fleetSharedComponentList: GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST,
  fleetEquipmentServiceList: GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST,
  fleetDriverPayrollAccessorialList: GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST,
  fleetVendorPayrollAccessorialList: GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST,
  editTruckPage: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_FLEET_TRUCKS_EDIT),
  editDriverPage: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_FLEET_DRIVERS_EDIT),
  editTrailerPage: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_FLEET_TRAILERS_EDIT),
  editFleetVendorPage: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_FLEET_VENDOR_EDIT),
  // fleet profile
  fleetTruckProfile: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_FLEET_PROFILE_TRUCK),
  fleetDriverProfile: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_FLEET_PROFILE_DRIVER),
  fleetVendorProfile: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_FLEET_PROFILE_VENDOR),
  fleetTrailerProfile: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_FLEET_PROFILE_TRAILER),
  // IMPORT ROUTES
  imports: GC.ROUTE_PATH_IMPORT,
  // INTL ROUTES
  intlList: GC.ROUTE_PATH_INTL,
  // INVOICE ROUTES
  driverInvoice: GC.ROUTE_PATH_DRIVER_INVOICES_LIST,
  vendorInvoice: GC.ROUTE_PATH_VENDOR_INVOICES_LIST,
  carrierInvoice: GC.ROUTE_PATH_CARRIER_INVOICES_LIST,
  driverPayrollList: GC.ROUTE_PATH_DRIVER_PAYROLL_LIST,
  customerInvoice: GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST,
  serviceVendorInvoice: GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST,
  customerMasterInvoice: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
  // ITEM ROUTES
  itemsList: GC.ROUTE_PATH_ITEMS_LIST,
  // LOCATION ROUTES
  locationsList: GC.ROUTE_PATH_LOCATIONS_LIST,
  // NOTIFICATION ROUTES
  notificationList: GC.ROUTE_PATH_NOTIFICATIONS,
  // REFERENCE ROUTES
  referencesList: GC.ROUTE_PATH_REFERENCES_LIST,
  // REPORT FORMAT ROUTES
  reportsList: GC.ROUTE_PATH_REPORTS_LIST,
  getEditReportRoute: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_REPORTS_EDIT),
  getEditRouteReport: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_REPORTS_ROUTE_EDIT),
  // ROLE ROUTES
  rolesPage: GC.ROUTE_PATH_ROLES_LIST,
  createRolePage: GC.ROUTE_PATH_ROLES_NEW,
  getEditRolePage: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_ROLES_EDIT),
  // ROUTE ROUTES
  newDO: GC.ROUTE_PATH_DO_NEW,
  ediList: GC.ROUTE_PATH_EDI_LIST,
  getQuoteList: GC.ROUTE_PATH_QUOTE,
  liteNewDO: GC.ROUTE_PATH_LITE_DO_NEW,
  orderQuotes: GC.ROUTE_PATH_ORDER_QUOTES,
  loadDetails: GC.ROUTE_PATH_LOAD_DETAILS,
  routeBuilder: GC.ROUTE_PATH_ROUTE_BUILDER,
  carrierEdiList: GC.ROUTE_PATH_CARRIER_EDI_LIST,
  routeBuilderEvents: GC.ROUTE_PATH_LOAD_PLANNER,
  routeListLoad: GC.ROUTE_PATH_DISPATCH_BOARD_TEL,
  routeListOrder: GC.ROUTE_PATH_DISPATCH_BOARD_CLO,
  cloDetails: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_CLO),
  telDetails: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_TEL),
  getLoadDetails: (params: Object) => replaceKeysToParams(params, GC.ROUTE_PATH_LOAD),
  dispatchDetailsLoad: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_ROUTE_LOAD),
  dispatchDetailsOrder: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_ROUTE_ORDER),
  getRouteLoadList: (loadType: string) => replaceKeysToParams(loadType, GC.ROUTE_PATH_ROUTES_LIST),
  newDOBySourceType: (options: Object) => replaceKeysToParams(options, GC.ROUTE_PATH_DO_NEW_FROM_BY_SOURCE_TYPE),
  liteNewDOBySourceType: (options: Object) => replaceKeysToParams(options, GC.ROUTE_PATH_LITE_DO_NEW_BY_SOURCE_TYPE),
  // SEQUENCE ROUTES
  sequencesList: GC.ROUTE_PATH_SEQUENCES_LIST,
  // STYLING ROUTES
  stylingConfig: GC.ROUTE_PATH_STYLING,
  splashScreenConfig: GC.ROUTE_PATH_SPLASH_SCREEN_SETTINGS,
  // ROUTE ROUTES
  routeList: GC.ROUTE_PATH_ROUTE_REPORT_LIST,
  // TEL ROUTES
  telList: GC.ROUTE_PATH_TEL_REPORT_LIST,
  // USER ROUTES
  usersPage: GC.ROUTE_PATH_USERS_LIST,
  createUserPage: GC.ROUTE_PATH_USERS_NEW,
  getUserSettingsRoute: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_USERS_SETTINGS),
  // loader new
  doNew: GC.ROUTE_PATH_DO_NEW,
  // FUEL CARDS ROUTES
  fuelCards: GC.ROUTE_PATH_FUEL_CARDS,
  // TEMPLATE ROUTES
  doTemplates: GC.ROUTE_PATH_TEMPLATES_DO_LIST,
  containerTypes: GC.ROUTE_PATH_CONTAINER_TYPE_LIST,
  routeTemplates: GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST,
  containerTemplates: GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST,
  compensationTemplates: GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST,
  doTemplateDetails: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_TEMPLATE_DO_DETAILS),
  liteDoTemplateDetails: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS),
  // LOAD PLANNER TEMPLATE ROUTES
  loadPlannerTemplate: GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST,
  // CONTACT BOOK ROUTES
  contactBook: GC.ROUTE_PATH_CONTACT_BOOK,
  // CONTRACT ROUTES
  carrierContracts: GC.ROUTE_PATH_CARRIER_CONTRACTS,
  customerContracts: GC.ROUTE_PATH_CUSTOMER_CONTRACTS,
  carrierContractDetails: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_CARRIER_CONTRACT_DETAILS),
  // AVERAGE FUEL PRICE ROUTES
  averageFuelPrice: GC.ROUTE_PATH_AVERAGE_FUEL_PRICE,
  // SHARED ACCESSORIALS ROUTES
  sharedAccessorialList: GC.ROUTE_PATH_SHARED_ACCESSORIALS,
  // RATING GEO FENCING ZONE
  geoFencingZoneList: GC.ROUTE_PATH_GEO_FENCING_ZONE,
  // REPORT GEO FENCING ZONE
  reportGeoFencingZoneList: GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT,
  // TOLL CHARGES
  tollCharges: GC.ROUTE_PATH_TOLL_CHARGES,
  // IFTA REPORT
  iftaReport: GC.ROUTE_PATH_IFTA_REPORT,
  // DISPATCHING GROUP
  dispatchingGroup: GC.ROUTE_PATH_DISPATCH_GROUP,
  // PUBLIC CLO
  publicCLOList: GC.ROUTE_PATH_PUBLIC_CLO_LIST,
  // MOBILE AMOUS DRIVER V.2 ROUTES
  passwordSuccessPage: GC.ROUTE_PATH_NEW_PASSWORD_MOBILE_SUCCESS_PAGE,
  // NORMALIZE CURRENCY LIST
  normalizeCurrencyList: GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST,
  // COMMISSION ASSIGNMENT
  commissionAssignment: GC.ROUTE_PATH_COMMISSION_ASSIGNMENT,
  // CUSTOMER PORTAL
  customerPortal: GC.ROUTE_PATH_CUSTOMER_PORTAL,
  // IMPORT REPORT
  importReport: GC.ROUTE_PATH_IMPORT_REPORT,
  // CHARGE REPORT
  telRateChargeReport: GC.ROUTE_PATH_TEL_RATE_CHARGE_REPORT,
  cloRateChargeReport: GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT,
  // RATE SHOP
  rateShop: GC.ROUTE_PATH_RATE_SHOP,
  // route mass create report
  routeMassCreateReport: GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT,
  // task management
  taskManagementBoard: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD,
  taskManagementReport: GC.ROUTE_PATH_TASK_MANAGEMENT_REPORT,
  taskManagementBoardList: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD_LIST,
  // claim management
  claimManagementList: GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST,
  claimManagementClaimDetails: (guid: string) => replaceKeysToParams(guid, GC.ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS),
};

export default routesMap;

export const getOrderEntryRoute = () => {
  const quick = isQuickOrderEntry();

  return ifElse(isTrue(quick), routesMap.liteNewDO, routesMap.newDO);
};

export const getOrderTemplateRouteFunc = () => {
  const quick = isQuickOrderEntry();

  return ifElse(isTrue(quick), routesMap.liteDoTemplateDetails, routesMap.doTemplateDetails);
};

export const getOrderTemplateRoute = (guid: string) => {
  const getFunc = getOrderTemplateRouteFunc();

  return getFunc(guid);
};

export const getOrderBySourceRouteFunc = () => {
  const quick = isQuickOrderEntry();

  return ifElse(isTrue(quick), routesMap.liteNewDOBySourceType, routesMap.newDOBySourceType);
};

export const getOrderBySourceRoute = (options: Object) => {
  const getFunc = getOrderBySourceRouteFunc();

  return getFunc(options);
};

export const defaultLoginScreenOptions = [
  {
    route: routesMap.telList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_TRIP_LIST,
    label: getWindowLocale('titles:trip-list', 'Trip List'),
  },
  {
    route: routesMap.cloList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_ORDER_LIST,
    label: getWindowLocale('titles:order-list', 'Order List'),
  },
  {
    route: routesMap.routeListLoad,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_ROUTE_TRIP,
    label: getWindowLocale('titles:route-trip', 'Route Trip'),
  },
  {
    route: routesMap.routeListOrder,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_ROUTE_ORDER,
    label: getWindowLocale('titles:route-order', 'Route Order'),
  },
  {
    route: routesMap.carrierList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_CARRIER_LIST,
    label: getWindowLocale('titles:carrier-list', 'Carrier List'),
  },
  {
    route: routesMap.fleetDriverList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_DRIVER_LIST,
    label: getWindowLocale('titles:fleet-driver-list', 'Fleet Driver List'),
  },
  {
    route: routesMap.fleetTruckList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_TRUCK_LIST,
    label: getWindowLocale('titles:fleet-truck-list', 'Fleet Truck List'),
  },
  {
    route: routesMap.fleetTrailerList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_TRAILER_LIST,
    label: getWindowLocale('titles:fleet-trailer-list', 'Fleet Trailer List'),
  },
  {
    route: routesMap.fleetVendorList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_VENDOR_LIST,
    label: getWindowLocale('titles:fleet-vendor-list', 'Fleet Vendor List'),
  },
  {
    route: routesMap.customerInvoice,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_CUSTOMER_INVOICE_LIST,
    label: getWindowLocale('titles:customer-invoice-list', 'Customer Invoice List'),
  },
  {
    route: routesMap.customerMasterInvoice,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_CUSTOMER_MASTER_INVOICE_LIST,
    label: getWindowLocale('titles:customer-master-invoice-list', 'Customer Master Invoice List'),
  },
  {
    route: routesMap.driverInvoice,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_DRIVER_INVOICE_LIST,
    label: getWindowLocale('titles:driver-invoice-list', 'Driver Invoice List'),
  },
  {
    route: routesMap.vendorInvoice,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_VENDOR_INVOICE_LIST,
    label: getWindowLocale('titles:vendor-invoice-list', 'Vendor Invoice List'),
  },
  {
    route: routesMap.carrierInvoice,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_CARRIER_INVOICE_LIST,
    label: getWindowLocale('titles:carrier-invoice-list', 'Carrier Invoice List'),
  },
  {
    route: routesMap.driverPayrollList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_PAYROLL_LIST,
    label: getWindowLocale('titles:driver-payroll-list', 'Driver Payroll List'),
  },
  {
    route: routesMap.driversCard,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_DRIVER_CARDS,
    label: getWindowLocale('titles:driver-cards', 'Driver Cards'),
  },
  {
    route: routesMap.customerPortal,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_CUSTOMER_PORTAL,
    label: getWindowLocale('titles:customer-portal', 'Customer Portal'),
  },
  {
    route: routesMap.availableDrivers,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_AVAILABLE_DRIVERS,
    label: getWindowLocale('titles:available-drivers', 'Available Drivers'),
  },
];

export const customerDefaultLoginScreenOptions = [
  {
    route: routesMap.cloList,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_ORDER_LIST,
    label: getWindowLocale('titles:order-list', 'Order List'),
  },
  {
    route: routesMap.routeListOrder,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_ROUTE_ORDER,
    label: getWindowLocale('titles:route-order', 'Route Order'),
  },
  {
    route: routesMap.customerPortal,
    value: GC.DEFAULT_LOGIN_SCREEN_TYPE_CUSTOMER_PORTAL,
    label: getWindowLocale('titles:customer-portal', 'Customer Portal'),
  },
];
