import { put } from 'redux-saga/effects';
// common
import { ACTION_TYPE_CLEAN_FORM_REDUCER } from '../common/actions';
// helpers/constants
import * as GC from '../constants';
//////////////////////////////////////////////////

export const routesSagas = {
  [GC.ROUTE_PATH_HOME]: function* homeSaga() {
    yield put({ type: 'VISIT_HOME_PAGE' });
  },
  [GC.ROUTE_PATH_NAL_LOGIN]: function* homeSaga() {
    yield put({ type: GC.VISIT_NAL_LOGIN_PAGE });
  },
  [GC.ROUTE_PATH_BOSH_LOS_REDIRECT]: function* homeSaga() {
    yield put({ type: GC.VISIT_BOSH_REDIRECT_PAGE });
  },
  [GC.ROUTE_PATH_BOSH_LOS_CALLBACK]: function* homeSaga() {
    yield put({ type: GC.VISIT_BOSH_CALLBACK_PAGE });
  },
  // role
  [GC.ROUTE_PATH_ROLES_LIST]: function* rolesListSaga() {
    yield put({ type: GC.VISIT_ROLES_LIST_PAGE, payload: GC.ROUTE_PATH_ROLES_LIST });
  },
  [GC.ROUTE_PATH_ROLES_NEW]: function* roleNewSaga() {
    yield put({ type: GC.VISIT_ROLE_CREATE_PAGE, payload: GC.ROUTE_PATH_ROLES_NEW });
  },
  [GC.ROUTE_PATH_ROLES_EDIT]: function* roleEditSaga({ guid }: string) {
    yield put({
      type: GC.VISIT_ROLE_EDIT_PAGE,
      payload: {
        route: GC.ROUTE_PATH_ROLES_EDIT,
        guid,
      },
    });
  },
  // user
  [GC.ROUTE_PATH_PROFILE]: function* profileSaga() {
    yield put({ type: GC.VISIT_CURRENT_USER_PROFILE });
  },
  [GC.ROUTE_PATH_USERS_LIST]: function* usersListSaga() {
    yield put({
      type: GC.VISIT_USERS_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_USERS_LIST,
        reportType: GC.USER_REPORT,
      },
    });
  },
  [GC.ROUTE_PATH_USERS_NEW]: function* userNewSaga() {
    yield put({ type: GC.VISIT_USER_CREATE_PAGE, payload: GC.ROUTE_PATH_USERS_NEW });
  },
  [GC.ROUTE_PATH_USERS_SETTINGS]: function* usersEditSaga({ guid }: string) {
    yield put({
      type: GC.VISIT_USER_EDIT_PAGE,
      payload: {
        route: GC.ROUTE_PATH_USERS_SETTINGS,
        guid,
      },
    });
  },
  // location
  [GC.ROUTE_PATH_LOCATIONS_LIST]: function* locationsListSaga() {
    yield put({
      type: GC.VISIT_LOCATIONS_LIST_PAGE,
      payload: {
        listType: 'listUp',
        pathname: GC.ROUTE_PATH_LOCATIONS_LIST,
        reportType: GC.LOCATION_TEMPLATE_REPORT,
        checkVisitPage: GC.CHECK_VISIT_LOCATIONS_LIST_PAGE,
      },
    });
  },
  // item
  [GC.ROUTE_PATH_ITEMS_LIST]: function* itemsListSaga() {
    yield put({
      type: GC.VISIT_ITEMS_LIST_PAGE,
      payload: {
        listType: 'listUp',
        reportType: GC.ITEM_REPORT,
        pathname: GC.ROUTE_PATH_ITEMS_LIST,
        checkVisitPage: GC.CHECK_VISIT_ITEMS_LIST_PAGE,
      },
    });
  },
  // reference
  [GC.ROUTE_PATH_REFERENCES_LIST]: function* referencesListSaga() {
    yield put({
      type: GC.VISIT_REFERENCE_TYPES_LIST_PAGE,
      payload: GC.ROUTE_PATH_REFERENCES_LIST,
    });
  },
  // sequence
  [GC.ROUTE_PATH_SEQUENCES_LIST]: function* sequencesListSaga() {
    yield put({ type: GC.VISIT_SEQUENCES_LIST_PAGE, payload: GC.ROUTE_PATH_SEQUENCES_LIST });
  },
  // branch
  [GC.ROUTE_PATH_BRANCH_LIST]: function* branchListSaga() {
    yield put({
      type: GC.VISIT_BRANCH_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_BRANCH_LIST,
        reportType: GC.BRANCH_REPORT,
      },
    });
  },
  [GC.ROUTE_PATH_BRANCH_NEW]: function* branchNewSaga() {
    yield put({ type: GC.VISIT_BRANCH_CREATE_PAGE, payload: GC.ROUTE_PATH_BRANCH_NEW });
  },
  [GC.ROUTE_PATH_BRANCH_EDIT]: function* branchEditSaga({ guid }: string) {
    yield put({
      type: GC.VISIT_BRANCH_EDIT_PAGE,
      payload: {
        route: GC.ROUTE_PATH_BRANCH_EDIT,
        guid,
      },
    });
  },
  [GC.ROUTE_PATH_BRANCH_DETAIL]: function* branchDetailsSaga({ guid }: string) {
    yield put({
      type: GC.VISIT_BRANCH_DETAILS_PAGE,
      payload: {
        route: GC.ROUTE_PATH_BRANCH_DETAIL,
        guid,
      },
    });
  },
  // new DO
  [GC.ROUTE_PATH_DO_NEW]: function* doNew() {
    yield put({ type: ACTION_TYPE_CLEAN_FORM_REDUCER });
    yield put({
      type: GC.VISIT_NEW_DO_PAGE,
      payload: GC.ROUTE_PATH_DO_NEW,
    });
  },
  [GC.ROUTE_PATH_DO_NEW_FROM_BY_SOURCE_TYPE]: function* createDOBySourceType(options: Object) {
    yield put({
      type: GC.VISIT_NEW_DO_PAGE_BY_SOURCE_TYPE,
      payload: {
        ...options,
        route: GC.ROUTE_PATH_DO_NEW_FROM_BY_SOURCE_TYPE,
      },
    });
  },
  [GC.ROUTE_PATH_DO_QUOTE]: function* doQuote() {
    yield put({ type: ACTION_TYPE_CLEAN_FORM_REDUCER });
    yield put({
      payload: GC.ROUTE_PATH_DO_QUOTE,
      type: GC.VISIT_NEW_DO_QUOTE_PAGE,
    });
  },
  // lite new DO
  [GC.ROUTE_PATH_LITE_DO_NEW]: function* liteDoNew() {
    yield put({ type: ACTION_TYPE_CLEAN_FORM_REDUCER });
    yield put({
      type: GC.VISIT_LITE_NEW_DO_PAGE,
      payload: GC.ROUTE_PATH_LITE_DO_NEW,
    });
  },
  [GC.ROUTE_PATH_LITE_DO_NEW_BY_SOURCE_TYPE]: function* createLiteDOBySourceType(options: Object) {
    yield put({
      type: GC.VISIT_LITE_NEW_DO_PAGE_BY_SOURCE_TYPE,
      payload: {
        ...options,
        route: GC.ROUTE_PATH_LITE_DO_NEW_BY_SOURCE_TYPE,
      },
    });
  },
  // fleet
  [GC.ROUTE_PATH_FLEET_VENDOR_LIST]: function* fleetVendorListSaga() {
    const params = {
      type: GC.VISIT_FLEET_VENDOR_LIST_PAGE,
      payload: {
        reportType: GC.FLEET_VENDOR_REPORT,
        pathname: GC.ROUTE_PATH_FLEET_VENDOR_LIST,
        checkVisitPageType: GC.CHECK_VISIT_FLEET_VENDOR_LIST_PAGE,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_DRIVERS_LIST]: function* fleetDriversListSaga() {
    const params = {
      type: GC.VISIT_FLEET_DRIVER_LIST_PAGE,
      payload: {
        reportType: GC.FLEET_DRIVER_REPORT,
        pathname: GC.ROUTE_PATH_FLEET_DRIVERS_LIST,
        checkVisitPageType: GC.CHECK_VISIT_FLEET_DRIVERS_LIST_PAGE,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_TRAILERS_LIST]: function* fleetTrailersListSaga() {
    const params = {
      type: GC.VISIT_FLEET_TRAILERS_LIST_PAGE,
      payload: {
        reportType: GC.FLEET_TRAILER_REPORT,
        pathname: GC.ROUTE_PATH_FLEET_TRAILERS_LIST,
        checkVisitPageType: GC.CHECK_VISIT_FLEET_TRAILERS_LIST_PAGE,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_TRUCKS_LIST]: function* fleetTrucksListSaga() {
    const params = {
      type: GC.VISIT_FLEET_TRUCKS_LIST_PAGE,
      payload: {
        reportType: GC.FLEET_TRUCK_REPORT,
        pathname: GC.ROUTE_PATH_FLEET_TRUCKS_LIST,
        checkVisitPageType: GC.CHECK_VISIT_FLEET_TRUCKS_LIST_PAGE,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST]: function* fleetSharedComponentList() {
    const params = {
      type: GC.VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE,
      payload: {
        reportType: GC.FLEET_SHARED_COMPONENT_REPORT,
        pathname: GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST,
        checkVisitPageType: GC.CHECK_VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST]: function* fleetEquipmentServiceList() {
    const params = {
      type: GC.VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE,
      payload: {
        reportType: GC.FLEET_EQUIPMENT_SERVICE_REPORT,
        pathname: GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST,
        checkVisitPageType: GC.CHECK_VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE,
      },
    };

    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST]: function* fleetDriverPayrollAccessorialList() {
    const params = {
      type: GC.VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE,
      payload: {
        reportType: GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
        pathname: GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST,
        checkVisitPageType: GC.CHECK_VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE,
      },
    };

    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST]: function* fleetVENDORPayrollAccessorialList() {
    const params = {
      type: GC.VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE,
      payload: {
        reportType: GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
        pathname: GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST,
        checkVisitPageType: GC.CHECK_VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE,
      },
    };

    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_VENDOR_EDIT]: function* fleetVendorEditSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_FLEET_VENDOR_EDIT_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_FLEET_VENDOR_EDIT,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_TRAILERS_EDIT]: function* fleetTrailerEditSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_FLEET_TRAILER_EDIT_PAGE,
      payload: {
        route: GC.ROUTE_PATH_FLEET_TRAILERS_EDIT,
        guid,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_TRUCKS_EDIT]: function* fleetTruckEditSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_FLEET_TRUCK_EDIT_PAGE,
      payload: {
        route: GC.ROUTE_PATH_FLEET_TRUCKS_EDIT,
        guid,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_DRIVERS_EDIT]: function* fleetDriverEditSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_FLEET_DRIVER_EDIT_PAGE,
      payload: {
        route: GC.ROUTE_PATH_FLEET_DRIVERS_EDIT,
        guid,
      },
    };
    yield put(params);
  },
  // fleet-profile
  [GC.ROUTE_PATH_FLEET_PROFILE_DRIVER]: function* fleetProfileDriverSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_FLEET_PROFILE_DRIVER_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_FLEET_PROFILE_DRIVER,
      },
    };

    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_PROFILE_VENDOR]: function* fleetProfileVendorSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_FLEET_PROFILE_VENDOR_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_FLEET_PROFILE_VENDOR,
      },
    };

    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_PROFILE_TRUCK]: function* fleetProfileTruckSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_FLEET_PROFILE_TRUCK_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_FLEET_PROFILE_TRUCK,
      },
    };

    yield put(params);
  },
  [GC.ROUTE_PATH_FLEET_PROFILE_TRAILER]: function* fleetProfileTrailerSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_FLEET_PROFILE_TRAILER_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_FLEET_PROFILE_TRAILER,
      },
    };

    yield put(params);
  },
  // report
  [GC.ROUTE_PATH_REPORTS_LIST]: function* reportFormatsListSaga() {
    yield put({
      type: GC.VISIT_REPORTS_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_REPORTS_LIST,
        reportType: GC.REPORT_REPORT,
      },
    });
  },
  // intl
  [GC.ROUTE_PATH_INTL]: function* intlListSaga() {
    yield put({
      type: GC.VISIT_TRANSLATION_PAGE,
      payload: GC.ROUTE_PATH_INTL,
    });
  },
  // load clo
  [GC.ROUTE_PATH_CLO]: function* cloSaga({ guid }: Object) {
    yield put({
      type: GC.VISIT_DISPATCH_DETAILS_ORDER_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_ROUTE_ORDER,
      },
    });
  },
  // quotes list
  [GC.ROUTE_PATH_QUOTE]: function* quotesList() {
    yield put({ type: GC.VISIT_QUOTES_LIST_PAGE });
  },
  // order quotes list
  [GC.ROUTE_PATH_ORDER_QUOTES]: function* orderQuotesList() {
    yield put({ type: GC.VISIT_ORDER_QUOTES_LIST_PAGE });
  },
  // edi list
  [GC.ROUTE_PATH_EDI_LIST]: function* ediListSaga() {
    yield put({
      type: GC.VISIT_EDI_LIST_PAGE,
      payload: {
        reportType: GC.EDI_REPORT,
        pathname: GC.ROUTE_PATH_EDI_LIST,
      },
    });
  },
  // carrier edi
  [GC.ROUTE_PATH_CARRIER_EDI_LIST]: function* carrierEdiList() {
    yield put({
      type: GC.VISIT_CARRIER_EDI_LIST_PAGE,
      payload: {
        reportType: GC.INVOICE_EDI_REPORT,
        pathname: GC.ROUTE_PATH_CARRIER_EDI_LIST,
      },
    });
  },
  // public clo
  [GC.ROUTE_PATH_PUBLIC_CLO_LIST]: function* publicCLOListSaga() {
    yield put({
      type: GC.VISIT_PUBLIC_CLO_LIST_PAGE,
      payload: {
        reportType: GC.ORDER_REQUEST_REPORT,
        pathname: GC.ROUTE_PATH_PUBLIC_CLO_LIST,
      },
    });
  },
  // dispatch board tel
  [GC.ROUTE_PATH_DISPATCH_BOARD_TEL]: function* dispatchBoardTelSaga() {
    const params = {
      type: GC.VISIT_DISPATCH_BOARD_TEL_PAGE,
      payload: {
        route: GC.ROUTE_PATH_DISPATCH_BOARD_TEL,
      },
    };
    yield put(params);
  },
  // dispatch board clo
  [GC.ROUTE_PATH_DISPATCH_BOARD_CLO]: function* dispatchBoardCloSaga() {
    yield put({
      type: GC.VISIT_DISPATCH_BOARD_CLO_PAGE,
      payload: {
        route: GC.ROUTE_PATH_DISPATCH_BOARD_CLO,
      },
    });
  },
  // load builder
  [GC.ROUTE_PATH_ROUTE_BUILDER]: function* routeBuilderSaga() {
    yield put({
      type: GC.VISIT_ROUTE_BUILDER_PAGE,
      payload: {
        route: GC.ROUTE_PATH_ROUTE_BUILDER,
      },
    });
  },
  // load builder events
  [GC.ROUTE_PATH_LOAD_PLANNER]: function* routeBuilderEventsSaga() {
    yield put({
      type: GC.VISIT_LOAD_PLANNER_PAGE,
      payload: {
        route: GC.ROUTE_PATH_LOAD_PLANNER,
      },
    });
  },
  // load tel
  [GC.ROUTE_PATH_TEL]: function* telSaga({ guid }: Object) {
    yield put({
      type: GC.VISIT_DISPATCH_DETAILS_LOAD_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_ROUTE_LOAD,
      },
    });
  },
  // dispatch details load
  [GC.ROUTE_PATH_ROUTE_LOAD]: function* dispatchDetailsLoad({ guid }: Object) {
    yield put({
      type: GC.VISIT_DISPATCH_DETAILS_LOAD_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_ROUTE_LOAD,
      },
    });
  },
  // dispatch details order
  [GC.ROUTE_PATH_ROUTE_ORDER]: function* dispatchDetailsOrder({ guid }: Object) {
    yield put({
      type: GC.VISIT_DISPATCH_DETAILS_ORDER_PAGE,
      payload: {
        guid,
        route: GC.ROUTE_PATH_ROUTE_ORDER,
      },
    });
  },
  // configs
  [GC.ROUTE_PATH_CONFIG_TEL]: function* configTelSaga() {
    yield put({
      type: GC.VISIT_CONFIG_TEL_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_TEL,
    });
  },
  [GC.ROUTE_PATH_CONFIG_CLO]: function* configCloSaga() {
    yield put({
      type: GC.VISIT_CONFIG_CLO_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_CLO,
    });
  },
  [GC.ROUTE_PATH_CONFIG_OPTIONAL]: function* configCloSaga() {
    yield put({
      type: GC.VISIT_CONFIG_OPTIONAL_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_OPTIONAL,
    });
  },
  [GC.ROUTE_PATH_CONFIG_PASSWORD]: function* configPasswordSaga() {
    yield put({
      type: GC.VISIT_CONFIG_PASSWORD_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_PASSWORD,
    });
  },
  [GC.ROUTE_PATH_CONFIG_GENERAL]: function* configGeneralSaga() {
    yield put({
      type: GC.VISIT_CONFIG_GENERAL_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_GENERAL,
    });
  },
  [GC.ROUTE_PATH_CONFIG_TEMPLATES]: function* configTemplatesSaga() {
    yield put({
      type: GC.VISIT_CONFIG_TEMPLATES_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_TEMPLATES,
    });
  },
  [GC.ROUTE_PATH_CONFIG_TRUCK]: function* configTruckSaga() {
    yield put({
      type: GC.VISIT_CONFIG_TRUCK_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_TRUCK,
    });
  },
  [GC.ROUTE_PATH_CONFIG_DRIVER]: function* configTemplatesSaga() {
    yield put({
      type: GC.VISIT_CONFIG_DRIVER_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_DRIVER,
    });
  },
  [GC.ROUTE_PATH_CONFIG_TRAILER]: function* configTrailerSaga() {
    yield put({
      type: GC.VISIT_CONFIG_TRAILER_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_TRAILER,
    });
  },
  [GC.ROUTE_PATH_CONFIG_RATING]: function* configInvoicesSaga() {
    yield put({
      type: GC.VISIT_CONFIG_RATING_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_RATING,
    });
  },
  [GC.ROUTE_PATH_CONFIG_INVOICES]: function* configInvoicesSaga() {
    yield put({
      type: GC.VISIT_CONFIG_INVOICES_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_INVOICES,
    });
  },
  [GC.ROUTE_PATH_CONFIG_CARRIER]: function* configCarrierSaga() {
    yield put({
      type: GC.VISIT_CONFIG_CARRIER_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_CARRIER,
    });
  },
  [GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR]: function* configServiceVendorSaga() {
    yield put({
      type: GC.VISIT_CONFIG_SERVICE_VENDOR_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR,
    });
  },
  [GC.ROUTE_PATH_CONFIG_COMMUNICATION]: function* configCommunicationSaga() {
    yield put({
      type: GC.VISIT_CONFIG_COMMUNICATION_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_COMMUNICATION,
    });
  },
  [GC.ROUTE_PATH_CONFIG_UI]: function* configInvoicesSaga() {
    yield put({
      type: GC.VISIT_CONFIG_UI_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_UI,
    });
  },
  [GC.ROUTE_PATH_CONFIG_MOBILEAPP]: function* configMobileAppSaga() {
    yield put({
      type: GC.VISIT_CONFIG_MOBILEAPP_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_MOBILEAPP,
    });
  },
  [GC.ROUTE_PATH_CONFIG_INTEGRATION]: function* configInvoicesSaga() {
    yield put({
      type: GC.VISIT_CONFIG_INTEGRATION_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_INTEGRATION,
    });
  },
  [GC.ROUTE_PATH_CONFIG_AUDIT]: function* configAuditSaga() {
    yield put({
      type: GC.VISIT_CONFIG_AUDIT_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_AUDIT,
    });
  },
  [GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT]: function* configTaskManagementSaga() {
    yield put({
      type: GC.VISIT_CONFIG_TASK_MANAGEMENT_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT,
    });
  },
  [GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT]: function* configClaimManagementSaga() {
    yield put({
      type: GC.VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE,
      payload: GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT,
    });
  },
  // invoice
  [GC.ROUTE_PATH_CARRIER_INVOICES_LIST]: function* invoiceCarrierListSaga() {
    const params = {
      type: GC.VISIT_INVOICE_CARRIER_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CARRIER_INVOICES_LIST,
        reportType: GC.CARRIER_INVOICE_REPORT,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST]: function* invoiceCustomerListSaga() {
    const params = {
      type: GC.VISIT_INVOICE_CUSTOMER_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST,
        reportType: GC.CUSTOMER_INVOICE_REPORT,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_DRIVER_INVOICES_LIST]: function* invoiceDriverPayrollListSaga() {
    const params = {
      type: GC.VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_DRIVER_INVOICES_LIST,
        reportType: GC.DRIVER_PAYROLL_INVOICE_REPORT,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_VENDOR_INVOICES_LIST]: function* invoiceVendorListSaga() {
    const params = {
      type: GC.VISIT_INVOICE_VENDOR_LIST_PAGE,
      payload: {
        reportType: GC.VENDOR_INVOICE_REPORT,
        pathname: GC.ROUTE_PATH_VENDOR_INVOICES_LIST,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST]: function* invoiceServiceVendorListSaga() {
    const params = {
      type: GC.VISIT_INVOICE_SERVICE_VENDOR_LIST_PAGE,
      payload: {
        reportType: GC.SERVICE_VENDOR_INVOICE_REPORT,
        pathname: GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST,
      },
    };
    yield put(params);
  },
  // tel report
  [GC.ROUTE_PATH_TEL_REPORT_LIST]: function* telListSaga() {
    const params = {
      type: GC.VISIT_TEL_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_TEL_REPORT_LIST,
        reportType: GC.TEL_REPORT,
      },
    };
    yield put(params);
  },
  // clo report
  [GC.ROUTE_PATH_CLO_REPORT_LIST]: function* cloListSaga() {
    const params = {
      type: GC.VISIT_CLO_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CLO_REPORT_LIST,
        reportType: GC.CLO_REPORT,
      },
    };
    yield put(params);
  },
  // route report
  [GC.ROUTE_PATH_ROUTE_REPORT_LIST]: function* routeListSaga() {
    const params = {
      type: GC.VISIT_ROUTE_REPORT_LIST_PAGE,
      payload: {
        reportType: GC.ROUTE_REPORT,
        pathname: GC.ROUTE_PATH_ROUTE_REPORT_LIST,
      },
    };
    yield put(params);
  },
  // analytics
  [GC.ROUTE_PATH_FLEET_MAP]: function* analyticsMapPageSaga() {
    const params = {
      type: GC.VISIT_ANALYTIC_MAPS_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_FLEET_MAP,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_ANALYTICS]: function* analyticsPageSaga() {
    const params = {
      type: GC.VISIT_ANALYTICS_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_ANALYTICS,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_DASHBOARDS_PAYROLL]: function* dashboardsPayrollPageSaga() {
    const params = {
      type: GC.VISIT_DASHBOARDS_PAYROLL_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_DASHBOARDS_PAYROLL,
      },
    };
    yield put(params);
  },
  // charts
  [GC.ROUTE_PATH_CHARTS_NEW]: function* chartsNewSaga() {
    const params = {
      type: GC.VISIT_CHART_CREATE_PAGE,
      payload: GC.ROUTE_PATH_CHARTS_NEW,
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_CHARTS_LIST]: function* chartsPageSaga() {
    const params = {
      type: GC.VISIT_CHART_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CHARTS_LIST,
        reportType: GC.CHART_REPORT,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_CHARTS_EDIT]: function* chartsEditSaga({ guid }: Object) {
    const params = {
      type: GC.VISIT_CHART_EDIT_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CHARTS_EDIT,
        guid,
      },
    };
    yield put(params);
  },
  // dashboard
  [GC.ROUTE_PATH_DASHBOARDS]: function* analyticsPageSaga() {
    const params = {
      type: GC.VISIT_DASHBOARDS_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_DASHBOARDS,
      },
    };

    yield put(params);
  },
  // carrier
  [GC.ROUTE_PATH_CARRIER_EDIT]: function* carrierEditPage({ guid }: Object) {
    const params = {
      type: GC.VISIT_CARRIER_EDIT_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CARRIER_EDIT,
        guid,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_CARRIERS_LIST]: function* carriersPageSaga() {
    const params = {
      type: GC.VISIT_CARRIER_LIST_PAGE,
      payload: {
        reportType: GC.CARRIER_REPORT,
        pathname: GC.ROUTE_PATH_CARRIERS_LIST,
      },
    };

    yield put(params);
  },
  [GC.ROUTE_PATH_CARRIER_PROFILE]: function* carrierProfilePage({ guid }: Object) {
    const params = {
      type: GC.VISIT_CARRIER_PROFILE_PAGE,
      payload: {
        guid,
        pathname: GC.ROUTE_PATH_CARRIER_PROFILE,
      },
    };
    yield put(params);
  },
  // notification
  [GC.ROUTE_PATH_NOTIFICATIONS]: function* cloListSaga() {
    const params = {
      type: GC.VISIT_NOTIFICATION_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_NOTIFICATIONS,
      },
    };
    yield put(params);
  },
  // service vendor
  [GC.ROUTE_PATH_SERVICE_VENDOR_EDIT]: function* serviceVendorEditPage({ guid }: Object) {
    const params = {
      type: GC.VISIT_SERVICE_VENDOR_EDIT_PAGE,
      payload: {
        guid,
        pathname: GC.ROUTE_PATH_SERVICE_VENDOR_EDIT,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_SERVICE_VENDOR_LIST]: function* serviceVendorListPageSaga() {
    const params = {
      type: GC.VISIT_SERVICE_VENDOR_LIST_PAGE,
      payload: {
        reportType: GC.SERVICE_VENDOR_REPORT,
        pathname: GC.ROUTE_PATH_SERVICE_VENDOR_LIST,
      },
    };
    yield put(params);
  },
  // styling
  [GC.ROUTE_PATH_STYLING]: function* stylingPageSaga() {
    const params = {
      type: GC.VISIT_STYLING_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_STYLING,
      },
    };
    yield put(params);
  },
  // customer master invoice
  [GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST]: function* masterInvoicesPageSaga() {
    yield put({
      type: GC.VISIT_CUSTOMER_MASTER_INVOICES_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
        reportType: GC.CUSTOMER_MASTER_INVOICE_REPORT,
      },
    });
  },
  // driver payroll
  [GC.ROUTE_PATH_DRIVER_PAYROLL_LIST]: function* payrollsPageSaga() {
    yield put({
      type: GC.VISIT_DRIVER_PAYROLL_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_DRIVER_PAYROLL_LIST,
        reportType: GC.PAYROLL_REPORT,
      },
    });
  },
  // load board
  [GC.ROUTE_PATH_LOAD_BOARD]: function* payrollsPageSaga() {
    yield put({
      type: GC.VISIT_LOAD_BOARD_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_LOAD_BOARD,
      },
    });
  },
  // imports
  [GC.ROUTE_PATH_IMPORT]: function* importsPageSaga() {
    yield put({
      type: GC.VISIT_IMPORT_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_IMPORT,
      },
    });
  },
  // fuel cards
  [GC.ROUTE_PATH_FUEL_CARDS]: function* fuelCardsPageSaga() {
    yield put({
      type: GC.VISIT_FUEL_CARDS_PAGE,
      payload: {
        reportType: GC.FUEL_CARD_REPORT,
        pathname: GC.ROUTE_PATH_FUEL_CARDS,
      },
    });
  },
  [GC.ROUTE_PATH_TOLL_CHARGES]: function* tollChargesPageSaga() {
    yield put({
      type: GC.VISIT_TOLL_CHARGES_PAGE,
      payload: {
        reportType: GC.TOLL_CHARGE_REPORT,
        pathname: GC.ROUTE_PATH_TOLL_CHARGES,
      },
    });
  },
  [GC.ROUTE_PATH_IFTA_REPORT]: function* iftaReportPageSga() {
    yield put({
      type: GC.VISIT_IFTA_REPORT_PAGE,
      payload: {
        reportType: GC.IFTA_REPORT,
        pathname: GC.ROUTE_PATH_IFTA_REPORT,
      },
    });
  },
  // templates
  [GC.ROUTE_PATH_CONTAINER_TYPE_LIST]: function* containerTypeListSaga() {
    const params = {
      type: GC.VISIT_CONTAINER_TYPE_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CONTAINER_TYPE_LIST,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST]: function* containerTemplateListSaga() {
    const params = {
      type: GC.VISIT_CONTAINER_TEMPLATE_LIST_PAGE,
      payload: {
        listType: 'listUp',
        reportType: GC.CONTAINER_TEMPLATE_REPORT,
        pathname: GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST,
        checkVisitPage: GC.CHECK_VISIT_CONTAINER_TEMPLATE_LIST_PAGE,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_TEMPLATES_DO_LIST]: function* doTemplateListSaga() {
    const params = {
      type: GC.VISIT_DO_TEMPLATE_LIST_PAGE,
      payload: {
        listType: 'list',
        reportType: GC.CLO_TEMPLATE_REPORT,
        pathname: GC.ROUTE_PATH_TEMPLATES_DO_LIST,
        checkVisitPage: GC.CHECK_VISIT_DO_TEMPLATE_LIST_PAGE,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST]: function* routeTemplateListSaga() {
    const params = {
      type: GC.VISIT_ROUTE_TEMPLATE_LIST_PAGE,
      payload: {
        listType: 'list',
        reportType: GC.ROUTE_TEMPLATE_REPORT,
        pathname: GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST,
        checkVisitPage: GC.CHECK_VISIT_ROUTE_TEMPLATE_LIST_PAGE,
      },
    };
    yield put(params);
  },
  [GC.ROUTE_PATH_TEMPLATE_DO_DETAILS]: function* templateSaga({ guid }: Object) {
    yield put({
      type: GC.VISIT_TEMPLATE_DETAILS_PAGE,
      payload: {
        route: GC.ROUTE_PATH_TEMPLATE_DO_DETAILS,
        guid,
      },
    });
  },
  [GC.ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS]: function* templateSaga({ guid }: Object) {
    yield put({
      type: GC.VISIT_LITE_TEMPLATE_DETAILS_PAGE,
      payload: {
        route: GC.ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS,
        guid,
      },
    });
  },
  [GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST]: function* compensationTemplateListSaga() {
    const params = {
      type: GC.VISIT_COMPENSATION_TEMPLATE_LIST_PAGE,
      payload: {
        listType: 'listUp',
        reportType: GC.COMPENSATION_TEMPLATE_REPORT,
        pathname: GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST,
        checkVisitPage: GC.CHECK_VISIT_COMPENSATION_TEMPLATE_LIST_PAGE,
      },
    };

    yield put(params);
  },
  // load-planner-template
  [GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST]: function* loadPlannerTemplateListSaga() {
    yield put({
      type: GC.VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE,
      payload: {
        listType: 'list',
        pathname: GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST,
        checkVisitPage: GC.CHECK_VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE,
      },
    });
  },
  // contact-book
  [GC.ROUTE_PATH_CONTACT_BOOK]: function* contactBookPageSaga() {
    yield put({
      type: GC.VISIT_CONTACT_BOOK_PAGE,
      payload: {
        listType: 'listUp',
        reportType: GC.CONTACT_BOOK_REPORT,
        pathname: GC.ROUTE_PATH_CONTACT_BOOK,
        checkVisitPage: GC.CHECK_VISIT_CONTACT_BOOK_PAGE,
      },
    });
  },
  [GC.ROUTE_PATH_SHARED_ACCESSORIALS]: function* sharedAccessorialPageSaga() {
    yield put({
      type: GC.VISIT_SHARED_ACCESORIALS_PAGE,
      payload: {
        listType: 'list',
        reportType: GC.SHARED_ACCESSORIAL_REPORT,
        pathname: GC.ROUTE_PATH_SHARED_ACCESSORIALS,
        checkVisitPage: GC.CHECK_VISIT_CONTACT_BOOK_PAGE,
      },
    });
  },
  [GC.ROUTE_PATH_AVERAGE_FUEL_PRICE]: function* averageFuelPrice() {
    yield put({
      type: GC.VISIT_AVERAGE_FUEL_PRICE_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_AVERAGE_FUEL_PRICE,
      },
    });
  },
  // carrier contracts
  [GC.ROUTE_PATH_CARRIER_CONTRACTS]: function* averageFuelPrice() {
    yield put({
      type: GC.VISIT_CARRIER_CONTRACTS_PAGE,
      payload: {
        reportType: GC.CARRIER_CONTRACT_REPORT,
        pathname: GC.ROUTE_PATH_CARRIER_CONTRACTS,
      },
    });
  },
  [GC.ROUTE_PATH_CARRIER_CONTRACT_DETAILS]: function* carrierContractDetails({ guid }: Object) {
    yield put({
      type: GC.VISIT_CARRIER_CONTRACT_DETAILS_PAGE,
      payload: {
        guid,
        pathname: GC.ROUTE_PATH_CARRIER_CONTRACT_DETAILS,
      },
    });
  },
  // customer contracts
  [GC.ROUTE_PATH_CUSTOMER_CONTRACTS]: function* averageFuelPrice() {
    yield put({
      type: GC.VISIT_CUSTOMER_CONTRACTS_PAGE,
      payload: {
        reportType: GC.CUSTOMER_CONTRACT_REPORT,
        pathname: GC.ROUTE_PATH_CUSTOMER_CONTRACTS,
      },
    });
  },
  // rating geo fencing zone
  [GC.ROUTE_PATH_GEO_FENCING_ZONE]: function* ratingGeoFencingZone() {
    yield put({
      type: GC.VISIT_RATING_GEO_FENCING_ZONE_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_GEO_FENCING_ZONE,
      },
    });
  },
  // report geo fencing zone
  [GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT]: function* reportGeoFencingZone() {
    yield put({
      type: GC.VISIT_REPORT_GEO_FENCING_ZONE_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT,
      },
    });
  },
  // drivers card
  [GC.ROUTE_PATH_DRIVERS_CARD]: function* averageFuelPrice() {
    yield put({
      type: GC.VISIT_DRIVERS_CARD_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_DRIVERS_CARD,
      },
    });
  },
  // dispatch group
  [GC.ROUTE_PATH_DISPATCH_GROUP]: function* dispatchGroup() {
    yield put({
      type: GC.VISIT_DISPATCH_GROUP_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_DISPATCH_GROUP,
      },
    });
  },
  // normalize currency list
  [GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST]: function* normalizeCurrencyList() {
    yield put({
      type: GC.VISIT_NORMALIZE_CURRENCY_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST,
      },
    });
  },
  // commission assignment
  [GC.ROUTE_PATH_COMMISSION_ASSIGNMENT]: function* commissionAssignmentPageSaga() {
    yield put({
      type: GC.VISIT_COMMISSION_ASSIGNMENT_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_COMMISSION_ASSIGNMENT,
      },
    });
  },
  // carrier-rating-report
  [GC.ROUTE_PATH_CARRIER_RATING_REPORT]: function* carrierRatingReport() {
    yield put({
      type: GC.VISIT_CARRIER_RATING_REPORT_LIST_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CARRIER_RATING_REPORT,
      },
    });
  },
  // customer-portal
  [GC.ROUTE_PATH_CUSTOMER_PORTAL]: function* customerPortal() {
    yield put({
      type: GC.VISIT_CUSTOMER_PORTAL_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CUSTOMER_PORTAL,
      },
    });
  },
  // import-report
  [GC.ROUTE_PATH_IMPORT_REPORT]: function* customerPortal() {
    yield put({
      type: GC.VISIT_IMPORT_REPORT_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_IMPORT_REPORT,
      },
    });
  },
  // charge-report
  [GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT]: function* cloChargeReport() {
    yield put({
      type: GC.VISIT_CLO_RATE_CHARGE_REPORT_PAGE,
      payload: {
        reportType: GC.CLO_RATE_CHARGE_REPORT,
        pathname: GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT,
        checkVisitPage: GC.CHECK_VISIT_CLO_RATE_CHARGE_REPORT_PAGE,
      },
    });
  },
  [GC.ROUTE_PATH_TEL_RATE_CHARGE_REPORT]: function* telChargeReport() {
    yield put({
      type: GC.VISIT_TEL_RATE_CHARGE_REPORT_PAGE,
      payload: {
        reportType: GC.TEL_RATE_CHARGE_REPORT,
        pathname: GC.ROUTE_PATH_TEL_RATE_CHARGE_REPORT,
        checkVisitPage: GC.CHECK_VISIT_TEL_RATE_CHARGE_REPORT_PAGE,
      },
    });
  },
  [GC.ROUTE_PATH_RATE_SHOP]: function* rateShop() {
    yield put({
      type: GC.VISIT_RATE_SHOP_PAGE,
      payload: {
        pathname: GC.VISIT_RATE_SHOP_PAGE,
        reportType: GC.EXCEL_RATING_RESULT_REPORT,
      },
    });
  },
  // fleet-general config
  [GC.ROUTE_PATH_CONFIG_FLEET_GENERAL]: function* fleetGeneralConfig() {
    yield put({
      type: GC.VISIT_CONFIG_FLEET_GENERAL_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_CONFIG_FLEET_GENERAL,
      },
    });
  },
  // route-mass-create-report
  [GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT]: function* routeMassCreateReport() {
    yield put({
      type: GC.VISIT_ROUTE_MASS_CREATE_REPORT_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT,
      },
    });
  },
  // los-subscription
  [GC.ROUTE_PATH_LOS_SUBCRIPTION_LIST]: function* losSubscriptionPageSaga() {
    yield put({
      type: GC.VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE,
      payload: {
        reportType: GC.LOS_SUBSCRIPTION_REPORT,
        pathname: GC.ROUTE_PATH_LOS_SUBCRIPTION_LIST,
      },
    });
  },
  // task-management
  [GC.ROUTE_PATH_TASK_MANAGEMENT_REPORT]: function* taskManagementReport() {
    yield put({
      type: GC.VISIT_TASK_MANAGEMENT_PAGE,
      payload: {
        reportType: GC.TASK_MANAGEMENT_REPORT,
        pathname: GC.ROUTE_PATH_TASK_MANAGEMENT_REPORT,
      },
    });
  },
  [GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD]: function* taskManagementBoard() {
    yield put({
      type: GC.VISIT_TASK_MANAGEMENT_PAGE,
      payload: {
        type: 'board',
        pathname: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD,
      },
    });
  },
  [GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD_LIST]: function* taskManagementBoard() {
    yield put({
      type: GC.VISIT_TASK_MANAGEMENT_PAGE,
      payload: {
        type: 'boardList',
        pathname: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD,
      },
    });
  },
  // available-driver
  [GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST]: function* availableDriverPageSaga() {
    yield put({
      type: GC.VISIT_ROUTE_AVAILABLE_DRIVER_PAGE,
      payload: {
        pathname: GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST,
      },
    });
  },
  // oauth-popup
  [GC.ROUTE_PATH_OAUTH2_POPUP]: function* oAuthPopupPageSaga() {
    yield put({ type: GC.VISIT_ROUTE_OAUTH_POPUP_PAGE });
  },
  // claim-management
  [GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST]: function* claimManagementList() {
    yield put({
      type: GC.VISIT_CLAIM_MANAGEMENT_LIST_PAGE,
      payload: {
        reportType: GC.CLAIM_REPORT,
        pathname: GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST,
      },
    });
  },
  [GC.ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS]: function* claimDetails({ guid }: Object) {
    yield put({
      type: GC.VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE,
      payload: {
        guid,
        pathname: GC.ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS,
      },
    });
  },
};
