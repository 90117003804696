import * as R from 'ramda';
import Select from 'react-select';
import React, { useMemo } from 'react';
import AsyncSelect from 'react-select/async';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// forms
import { renderBorderColor } from '../forms/formik/fieldset2/ui';
//////////////////////////////////////////////////

const makeDefaultStyles = ({
  width,
  height,
  hasError,
  useMaxHeight,
  valueContainerMaxHeight,
}: Object = {}) => ({
  container: (baseStyles: Object) => ({
    ...baseStyles,
    width,
  }),
  menu: (baseStyles: Object) => ({
    ...baseStyles,
    marginTop: G.ifElse(hasError, 2, 8),
  }),
  menuPortal: (baseStyles: Object) => ({
    ...baseStyles,
    zIndex: 1300,
    marginTop: 0,
  }),
  dropdownIndicator: (baseStyles: Object) => ({
    ...baseStyles,
    padding: '0 2px',
  }),
  clearIndicator: (baseStyles: Object) => ({
    ...baseStyles,
    padding: '0 2px',
  }),
  control: (baseStyles: Object) => ({
    ...baseStyles,
    borderRadius: 4,
    height: 'max-content',
    minHeight: R.or(height, 36),
    borderColor: renderBorderColor({ hasError }),
  }),
  valueContainer: (baseStyles: Object, props: Object) => {
    if (R.and(useMaxHeight, G.isNotNilAndNotEmpty(R.path(['selectProps', 'value'], props)))) {
      return {
        ...baseStyles,
        padding: '2px',
        overflow: 'auto',
        maxHeight: valueContainerMaxHeight,
      };
    }

    return baseStyles;
  },
});

const ReactSelect = (props: Object) => {
  const {
    value,
    width,
    height,
    isMulti,
    options,
    hasError,
    innerRef,
    useMaxHeight,
    additionalStyles,
    isClearable = true,
    useMenuPortalTarget,
    maxMenuHeight = 190,
    valueContainerMaxHeight,
    shouldNotGetValueFromOptions,
    noOptionsMessage = () => null,
  } = props;

  const styles = useMemo(() => ({
    ...makeDefaultStyles({ width, height, hasError, useMaxHeight, valueContainerMaxHeight }),
    ...additionalStyles,
  }), [hasError, additionalStyles]);

  const selectedValue = useMemo(() => {
    if (R.or(R.isNil(value), shouldNotGetValueFromOptions)) return value;

    if (isMulti) {
      return R.filter((item: Object) => R.includes(item.value, R.or(value, [])), R.or(options, []));
    }

    return R.find(R.propEq(value, GC.FIELD_VALUE), R.or(options, []));
  }, [value, options, isMulti]);

  const menuProps = G.ifElse(
    useMenuPortalTarget,
    {
      menuPlacement: 'auto',
      menuPortalTarget: document.body,
      menuShouldScrollIntoView: false,
    },
  );

  const reactSelectProps = {
    ...menuProps,
    ...R.omit(
      [
        'value',
        'height',
        'hasError',
        'useMaxHeight',
        'additionalStyles',
        'useMenuPortalTarget',
        'valueContainerMaxHeight',
        'shouldNotGetValueFromOptions',
      ],
      props,
    ),
    styles,
    isClearable,
    maxMenuHeight,
    noOptionsMessage,
    value: selectedValue,
  };

  return <Select {...reactSelectProps} ref={innerRef} />;
};

const ReactAsyncSelect = (props: Object) => {
  const { id, width, placeholder, getLoadOptions, handleAsyncSearchSelectChange } = props;

  const styles = useMemo(() => ({
    ...makeDefaultStyles({ width }),
  }), []);

  return (
    <AsyncSelect
      id={id}
      styles={styles}
      cacheOptions={true}
      maxMenuHeight={190}
      placeholder={placeholder}
      onChange={handleAsyncSearchSelectChange}
      loadOptions={G.setDebounce(getLoadOptions, 1000)}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export {
  ReactSelect,
  ReactAsyncSelect,
};
