import { router } from 'redux-saga-router';
import { fork, call, put } from 'redux-saga/effects';
// helpers
import { history } from './helpers/route';
// components
import orderTableWatcherSaga from './common/idb/order/sagas';
import resizeTableWatcherSaga from './common/idb/resize/sagas';
// features
import storageWatcherSaga from './storage/sagas';
import intlWatcherSaga from './features/intl/sagas';
import userWatcherSaga from './features/user/sagas';
import rateWatcherSaga from './features/rate/sagas';
import rolesWatcherSaga from './features/role/sagas';
import menusWatcherSaga from './features/menu/sagas';
import ItemsWatcherSaga from './features/item/sagas';
import auditWatcherSaga from './features/audit/sagas';
import newDOWatcherSaga from './features/new-do/sagas';
import audit2WatcherSaga from './features/audit2/sagas';
import branchWatcherSaga from './features/branch/sagas';
import chartsWatcherSaga from './features/charts/sagas';
import quotesWatcherSaga from './features/quotes/sagas';
import localesWatcherSaga from './features/locale/sagas';
import payrollWatcherSaga from './features/payroll/sagas';
import carrierWatcherSaga from './features/carrier/sagas';
import importWatcherSaga from './features/new-import/sagas';
import navbarWatcherSaga from './features/navbar-menu/sagas';
import trucksWatcherSaga from './features/fleet/truck/sagas';
import rateShopWatcherSaga from './features/rate-shop/sagas';
import SequencesWatcherSaga from './features/sequence/sagas';
import locationsWatcherSaga from './features/location/sagas';
import fleetMapWatcherSaga from './features/fleet-map/sagas';
import dashboardWatcherSaga from './features/dashboard/sagas';
import ediWatcherSaga from './features/edi-integration/sagas';
import publicCloWatcherSaga from './features/public-clo/sagas';
import fuelCardsWatcherSaga from './features/fuel-cards/sagas';
import driversWatcherSaga from './features/fleet/driver/sagas';
import fleetListWatcherSaga from './features/fleet-list/sagas';
import ReferencesWatcherSaga from './features/reference/sagas';
import userSettingsWatcherSaga from './features/profile/sagas';
import loadBoardWatcherSaga from './features/load-board/sagas';
import dashboardsWatcherSaga from './features/dashboards/sagas';
import configWatcherSaga from './features/configurations/sagas';
import liteNewDOWatcherSaga from './features/lite-new-do/sagas';
import AppReleaseWatcherSaga from './features/app-release/sagas';
import trailersWatcherSaga from './features/fleet/trailer/sagas';
import carrierEdiWatcherSaga from './features/carrier-edi/sagas';
import iftaReportWatcherSaga from './features/ifta-report/sagas';
import permissionsWatcherSaga from './features/permission/sagas';
import pivotTableWatcherSaga from './features/pivot-table/sagas';
import oAuthPopupWatcherSaga from './features/oauth-popup/sagas';
import newDOQuoteWatcherSaga from './features/new-do-quote/sagas';
import fleetVendorWatcherSaga from './features/fleet/vendor/sagas';
import tollChargesWatcherSaga from './features/toll-charges/sagas';
import MailSendingWatcherSaga from './features/mail-sending/sagas';
import driversCardWatcherSaga from './features/drivers-card/sagas';
import notificationWatcherSaga from './features/notification/sagas';
import importReportWatcherSaga from './features/import-report/sagas';
import chargeReportWatcherSaga from './features/charge-report/sagas';
import loginWatcherSaga, { sessionSaga } from './features/auth/sagas';
import reportFormatsWatcherSaga from './features/report-format/sagas';
import vendorInvoiceWatcherSaga from './features/invoice/vendor/sagas';
import dispatchGroupWatcherSaga from './features/dispatch-group/sagas';
import carrierPortalWatcherSaga from './features/carrier-portal/sagas';
import serviceVendorWatcherSaga from './features/service-vendor/sagas';
import loadListWatcherSaga from './features/dispatch-report/load/sagas';
import taskManagementWatcherSaga from './features/task-management/sagas';
import customerPortalWatcherSaga from './features/customer-portal/sagas';
import carrierInvoiceWatcherSaga from './features/invoice/carrier/sagas';
import templateReportWatcherSaga from './features/template/report/sagas';
import orderListWatcherSaga from './features/dispatch-report/order/sagas';
import availableDriverWatcherSaga from './features/available-driver/sagas';
import customerInvoiceWatcherSaga from './features/invoice/customer/sagas';
import dispatchPlannerWatcherSaga from './features/dispatch-planner/sagas';
import losSubscriptionWatcherSaga from './features/los-subscription/sagas';
import claimManagementWatcherSaga from './features/claim-management/sagas';
import carrierContractsWatcherSaga from './features/contracts/carrier/sagas';
import averageFuelPriceWatcherSaga from './features/average-fuel-price/sagas';
import driverPayrollInvoiceWatcherSaga from './features/invoice/driver/sagas';
import customerContractsWatcherSaga from './features/contracts/customer/sagas';
import customerMasterInvoiceWatcherSaga from './features/master-invoice/sagas';
import dashboardsPayrollWatcherSaga from './features/dashboards-payroll/sagas';
import vendorPayrollWatcherSaga from './features/payroll/vendor-payroll/sagas';
import routeReportListWatcherSaga from './features/dispatch-report/route/sagas';
import containerTypesWatcherSaga from './features/template/container-type/sagas';
import configPagesWatcherSaga from './features/configurations/config-pages/sagas';
import routeMassCreateReportSaga from './features/route-mass-create-report/sagas';
import loadDetailsNewWatcherSaga from './features/dispatch-details-new/load/sagas';
import normalizeCurrencyWatcherSaga from './features/normalize-currency-list/sagas';
import driverProfileSimpleWatcherSaga from './features/driver-profile-simple/sagas';
import carrierStatisticWatcherSaga from './features/carrier-statistic-report/sagas';
import loadPlannerTemplateWatcherSaga from './features/load-planner-template/sagas';
import commissionAssignmentWatcherSaga from './features/commission-assignment/sagas';
import orderDetailsNewWatcherSaga from './features/dispatch-details-new/order/sagas';
import serviceVendorInvoiceWatcherSaga from './features/invoice/service-vendor/sagas';
import fleetVisitPagesWatcherSaga from './features/fleet-list/sagas/visit-page-sagas';
import ratingGeoFencingZoneWatcherSaga from './features/geo-fencing-zone/rating/sagas';
import reportGeoFencingZoneWatcherSaga from './features/geo-fencing-zone/report/sagas';
import dispatchPlannerEventsWatcherSaga from './features/dispatch-planner-events/sagas';
import CarrierRatingReportListWatcherSaga from './features/carrier-rating-report/sagas';
import CarrierInsurancesReportListWatcherSaga from './features/carrier/insurances/sagas';
import carrierRateRequestWatcherSaga from './features/carrier-rate-request-report/sagas';
import stylingWatcherSaga, { getInitialSplashScreenSaga } from './features/styling/sagas';
import telEventsReportListWatcherSaga from './features/dispatch-report/load/load-events/sagas';
import telByCloReportListWatcherSaga from './features/dispatch-report/load/load-by-order/sagas';
import routeByLoadReportListWatcherSaga from './features/dispatch-report/route/route-by-load/sagas';
// new dispatch board
import dispatchBoardSaga from './features/dispatch-board-new/sagas';
import dispatchBoardTelSaga from './features/dispatch-board-new/load/sagas';
import dispatchBoardCloSaga from './features/dispatch-board-new/order/sagas';
// sockets
import socketWatcherSaga from './features/sockets/sagas';
import allDriversSocketWatcherSaga from './features/sockets/sagas/available-driver/all-drivers';
import availableDriversSocketWatcherSaga from './features/sockets/sagas/available-driver/available-drivers';
// fleet-profile
import fleetProfileWatcherSaga from './features/fleet-profile/sagas';
// carrier-profile
import carrierProfileWatcherSaga from './features/carrier-profile/sagas';
// forms
import TemplateSearchWatcherSaga from './forms/forms/template-search/sagas';
// helpers/constants
import * as GC from './constants';
import { getAuthTokenFromSession } from './helpers';
// sagas
import { routesSagas } from './sagas/routes-saga';
import { GlobalActionsWatcherSaga } from './sagas/global-actions-saga';
import { initialDataWatcherSaga, watchSwitchBranchSuccess } from './sagas/initial-data-saga';
////////////////////////////////////////////////

const options = {
  matchAll: true,
  * beforeRouteChange() {
    yield put({ type: GC.BEFORE_ROUTE_CHANGE });
  },
};

export default function* mainSaga() {
  yield [
    fork(ediWatcherSaga),
    fork(intlWatcherSaga),
    fork(userWatcherSaga),
    fork(rateWatcherSaga),
    fork(newDOWatcherSaga),
    fork(loginWatcherSaga),
    fork(rolesWatcherSaga),
    fork(menusWatcherSaga),
    fork(auditWatcherSaga),
    fork(ItemsWatcherSaga),
    fork(audit2WatcherSaga),
    fork(navbarWatcherSaga),
    fork(branchWatcherSaga),
    fork(configWatcherSaga),
    fork(trucksWatcherSaga),
    fork(importWatcherSaga),
    fork(chartsWatcherSaga),
    fork(quotesWatcherSaga),
    fork(driversWatcherSaga),
    fork(stylingWatcherSaga),
    fork(carrierWatcherSaga),
    fork(payrollWatcherSaga),
    fork(localesWatcherSaga),
    fork(storageWatcherSaga),
    fork(trailersWatcherSaga),
    fork(loadListWatcherSaga),
    fork(fleetMapWatcherSaga),
    fork(rateShopWatcherSaga),
    fork(publicCloWatcherSaga),
    fork(dashboardWatcherSaga),
    fork(orderListWatcherSaga),
    fork(fleetListWatcherSaga),
    fork(SequencesWatcherSaga),
    fork(locationsWatcherSaga),
    fork(loadBoardWatcherSaga),
    fork(fuelCardsWatcherSaga),
    fork(liteNewDOWatcherSaga),
    fork(pivotTableWatcherSaga),
    fork(ReferencesWatcherSaga),
    fork(AppReleaseWatcherSaga),
    fork(iftaReportWatcherSaga),
    fork(carrierEdiWatcherSaga),
    fork(newDOQuoteWatcherSaga),
    fork(dashboardsWatcherSaga),
    fork(oAuthPopupWatcherSaga),
    fork(orderTableWatcherSaga),
    fork(resizeTableWatcherSaga),
    fork(driversCardWatcherSaga),
    fork(permissionsWatcherSaga),
    fork(MailSendingWatcherSaga),
    fork(initialDataWatcherSaga),
    fork(tollChargesWatcherSaga),
    fork(fleetVendorWatcherSaga),
    fork(configPagesWatcherSaga),
    fork(chargeReportWatcherSaga),
    fork(userSettingsWatcherSaga),
    fork(notificationWatcherSaga),
    fork(importReportWatcherSaga),
    fork(vendorInvoiceWatcherSaga),
    fork(GlobalActionsWatcherSaga),
    fork(reportFormatsWatcherSaga),
    fork(watchSwitchBranchSuccess),
    fork(dispatchGroupWatcherSaga),
    fork(carrierPortalWatcherSaga),
    fork(vendorPayrollWatcherSaga),
    fork(serviceVendorWatcherSaga),
    fork(containerTypesWatcherSaga),
    fork(TemplateSearchWatcherSaga),
    fork(carrierInvoiceWatcherSaga),
    fork(customerPortalWatcherSaga),
    fork(loadDetailsNewWatcherSaga),
    fork(templateReportWatcherSaga),
    fork(routeMassCreateReportSaga),
    fork(taskManagementWatcherSaga),
    fork(fleetVisitPagesWatcherSaga),
    fork(customerInvoiceWatcherSaga),
    fork(dispatchPlannerWatcherSaga),
    fork(availableDriverWatcherSaga),
    fork(orderDetailsNewWatcherSaga),
    fork(routeReportListWatcherSaga),
    fork(losSubscriptionWatcherSaga),
    fork(claimManagementWatcherSaga),
    fork(carrierStatisticWatcherSaga),
    fork(averageFuelPriceWatcherSaga),
    fork(carrierContractsWatcherSaga),
    fork(customerContractsWatcherSaga),
    fork(dashboardsPayrollWatcherSaga),
    fork(normalizeCurrencyWatcherSaga),
    fork(telByCloReportListWatcherSaga),
    fork(carrierRateRequestWatcherSaga),
    fork(telEventsReportListWatcherSaga),
    fork(driverProfileSimpleWatcherSaga),
    fork(loadPlannerTemplateWatcherSaga),
    fork(driverPayrollInvoiceWatcherSaga),
    fork(commissionAssignmentWatcherSaga),
    fork(serviceVendorInvoiceWatcherSaga),
    fork(ratingGeoFencingZoneWatcherSaga),
    fork(reportGeoFencingZoneWatcherSaga),
    fork(customerMasterInvoiceWatcherSaga),
    fork(dispatchPlannerEventsWatcherSaga),
    fork(routeByLoadReportListWatcherSaga),
    fork(CarrierRatingReportListWatcherSaga),
    fork(CarrierInsurancesReportListWatcherSaga),
    // new dispatch board
    fork(dispatchBoardSaga),
    fork(dispatchBoardTelSaga),
    fork(dispatchBoardCloSaga),
    // fleet-profile
    fork(fleetProfileWatcherSaga),
    // carrier-profile
    fork(carrierProfileWatcherSaga),
    // sockets
    fork(socketWatcherSaga),
    fork(allDriversSocketWatcherSaga),
    fork(availableDriversSocketWatcherSaga),
  ];

  const token = yield call(getAuthTokenFromSession);

  yield call(sessionSaga, token);
  yield call(getInitialSplashScreenSaga);

  yield fork(router, history, routesSagas, options);
}
