import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import {
  grid,
  width,
  space,
  color,
  border,
  layout,
  shadow,
  system,
  flexbox,
  position,
  background,
  typography,
} from 'styled-system';
// features
import { makeSelectExpandedContainerOpened } from '../features/expanded-container/selectors';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

export const Box = styled.div`
  ${space}
  ${width}
  ${color}
  ${border}
  ${layout}
  ${shadow}
  ${flexbox}
  ${position}
  ${background}
  ${typography}

  resize: ${({ resize }: Object) => resize};
  cursor: ${({ cursor }: Object) => cursor};
  opacity: ${({ opacity }: Object) => opacity};
  text-wrap: ${({ textWrap }: Object) => textWrap};
  transform: ${({ transform }: Object) => transform};
  animation: ${({ animation }: Object) => animation};
  word-break: ${({ wordBreak }: Object) => wordBreak};
  transition: ${({ transition }: Object) => transition};
  visibility: ${({ visibility }: Object) => visibility};
  white-space: ${({ whiteSpace }: Object) => whiteSpace};
  user-select: ${({ userSelect }: Object) => userSelect};
  vertical-align: ${({ verticalAlign }: Object) => verticalAlign};
  text-transform: ${({ textTransform }: Object) => textTransform};
  text-decoration: ${({ textDecoration }: Object) => textDecoration};
`;

const gap = system({
  gap: {
    property: 'gap',
    transform: (value: any) => G.ifElse(G.notContain('px', value), `${value}px`, value),
  },
});

export const Flex = styled(Box)`
  ${gap}
`;

Flex.defaultProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'normal',
};

export const HideIfUserTypeCarrierBox = (props: Object) => {
  const { isFlex, children } = props;

  if (G.isCurrentUserTypeCarrier()) return null;

  if (isFlex) return <Flex {...props}>{children}</Flex>;

  return <Box {...props}>{children}</Box>;
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  opened: makeSelectExpandedContainerOpened(state),
});

export const HideIfExpandedContainerOpenedBox = connect(mapStateToProps)((props: Object) => {
  const { isFlex, opened, children } = props;

  if (G.isTrue(opened)) return null;

  if (isFlex) return <Flex {...props}>{children}</Flex>;

  return <Box {...props}>{children}</Box>;
});

export const HideIfExpandedContainerOpenedFromPageBox = connect(mapStateToProps)((props: Object) => {
  const { isFlex, opened, children, openedFromPage } = props;

  if (R.and(G.isTrue(opened), R.pathEq(openedFromPage, ['location', 'pathname'], window))) return null;

  if (isFlex) return <Flex {...props}>{children}</Flex>;

  return <Box {...props}>{children}</Box>;
});

export const Grid = styled(Box)`
  ${grid}
`;

Grid.defaultProps = {
  display: 'grid',
};

export const GridItem = styled(Box)`
  grid-column-end: ${({ gridColumnEnd }: Object) => gridColumnEnd};
  grid-column-start: ${({ gridColumnStart }: Object) => gridColumnStart};
`;

export const RelativeBox = styled(Box)`
  position: relative;
`;

export const RelativeFlex = styled(Flex)`
  position: relative;
`;

export const AbsoluteBox = styled(Flex)`
  position: absolute;
`;

export const StickedBox = styled(Box)`
  position: sticky;
`;

StickedBox.defaultProps = {
  zIndex: 1,
};

export const StickedFlex = styled(Flex)`
  position: sticky;
`;

StickedFlex.defaultProps = {
  zIndex: 1,
};

export const FixedBox = styled(Box)`
  position: fixed;
`;

export const FixedFlex = styled(Flex)`
  position: fixed;
`;

export const BoxHovered = styled(Box)`
  & .hover-visible { visibility: hidden };
  &:hover {
    color: ${({ hoverColor }: Object) => hoverColor};
    font-weight: ${({ hoverFontWeight }: Object) => hoverFontWeight};
    border-left: ${({ hoverBorderLeft }: Object) => hoverBorderLeft};
    text-decoration: ${({ hoverTextDecoration }: Object) => hoverTextDecoration};
    background-color: ${({ hoverBackgroundColor }: Object) => hoverBackgroundColor};
    & .hover-visible { visibility: visible };
  }
`;

export const FlexHovered = styled(Flex)`
  &:hover {
    border: ${({ border }: Object) => border};
    background-color: ${({ bgColor }: Object) => bgColor};
    transition: ${({ transition }: Object) => transition};
    font-weight: ${({ fontWeightHover }: Object) => fontWeightHover};
  }
`;

export const BoxWithChildDiv = styled(Box)`
  & > div {
    width: ${({ childWidth }: Object) => childWidth};
  }
`;

export const SectionsDivider = styled.div`
  ${space}

  height: 1px;
  width: 100%;
  border-top: solid 1px;

  border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('colors.light.darkGrey')};
`;

export const FlexDivider = styled(Flex)`
  border: none;
  border-right: solid 1px;

  border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('colors.light.darkGrey')};
`;

export const Divider = ({ my, text, width }: Object) => (
  <Flex width={width} my={my || '5px'}>
    <Box height='1px' flexGrow='1' bg={G.getTheme('colors.dark.grey')} />
    { text && <Box px='5px' color={G.getTheme('colors.dark.grey')}>{text}</Box> }
    <Box height='1px' flexGrow='1' bg={G.getTheme('colors.dark.grey')} />
  </Flex>
);

export const DisableEventsBox = styled(Box)`
  pointer-events: ${({ disableEvents }: Object) => G.ifElse(disableEvents, 'none', 'unset')};
`;
