import * as R from 'ramda';
import Select from 'react-select';
import styled from 'styled-components';
import usePlacesAutocomplete from 'use-places-autocomplete';
import React, { useRef, useMemo, useEffect, useCallback } from 'react';
// forms
import { renderBorderColor } from '../forms/formik/fieldset2/ui';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
//////////////////////////////////////////////////

const StyledInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  appearance: none;
  background: transparent;
`;

const makeDefaultStyles = ({
  width,
  height,
  hasError,
}: Object = {}) => ({
  container: (baseStyles: Object) => ({
    ...baseStyles,
    width,
  }),
  menu: (baseStyles: Object) => ({
    ...baseStyles,
    marginTop: G.ifElse(hasError, 2, 8),
  }),
  menuPortal: (baseStyles: Object) => ({
    ...baseStyles,
    zIndex: 1300,
    marginTop: 0,
  }),
  dropdownIndicator: (baseStyles: Object) => ({
    ...baseStyles,
    padding: '0 2px',
  }),
  clearIndicator: (baseStyles: Object) => ({
    ...baseStyles,
    padding: '0 2px',
  }),
  option: (baseStyles: Object, { isFocused }: Object) => ({
    ...baseStyles,
    color: 'inherit',
    backgroundColor: G.ifElse(isFocused, G.getTheme('colors.reactSelectOptionHoverBgColor')),
  }),
  control: (baseStyles: Object, { isFocused }: Object) => ({
    ...baseStyles,
    borderRadius: 4,
    height: 'max-content',
    minHeight: R.or(height, 36),
    borderColor: renderBorderColor({ hasError }),
    boxShadow: G.ifElse(G.isTrue(isFocused), '0 0 5px 0 rgba(206, 40, 40, 0.5)'),
    ':hover': {
      borderColor: G.ifElse(hasError, G.getTheme('forms.inputs.borderColorErr'), 'hsl(0, 0%, 70%)'),
    },
  }),
});

export const PlacesAutocomplete = (props: Object) => {
  const {
    id,
    value,
    width,
    height,
    hasError,
    disabled,
    handleBlur,
    placeholder,
    handleFocus,
    handleSelect,
    setFieldValue,
    additionalStyles,
    useMenuPortalTarget,
  } = props;

  const ref = useRef();

  const {
    ready,
    setValue,
    clearSuggestions,
    suggestions: { data },
  } = usePlacesAutocomplete({ debounce: 300 });

  const styles = useMemo(() => ({
    ...makeDefaultStyles({ width, height, hasError }),
    ...additionalStyles,
  }), [hasError, additionalStyles]);

  const components = useMemo(() => ({
    Placeholder: () => null,
    IndicatorsContainer: () => null,
    Input: (inputProps: Object) => (
      <StyledInput
        {...R.pick(['onBlur', 'onFocus', 'onChange', GC.FIELD_VALUE], inputProps)}
        type='text'
        placeholder={R.pathOr('', ['selectProps', 'placeholder'], inputProps)}
      />
    ),
  }), []);

  const handleKeyDown = useCallback(({ keyCode }: Object) => {
    if (R.includes(keyCode, [GC.EVENT_KEY_CODE_ARROW_UP, GC.EVENT_KEY_CODE_ARROW_DOWN])) {
      const current = R.path(['current', 'state', 'focusedOption', 'description'], ref);

      if (R.and(R.equals(R.length(data), 1), R.isNil(current))) return;
      const currentIndex = R.findIndex(R.propEq(current, GC.FIELD_DESCRIPTION), data);

      if (G.isAnyTrue(
        R.isNil(current),
        R.equals(R.inc(currentIndex), R.length(data)),
      )) return setFieldValue(id, R.path([0, 'description'], data));

      if (R.equals(keyCode, GC.EVENT_KEY_CODE_ARROW_DOWN)) {
        return setFieldValue(id, R.path([R.inc(currentIndex), 'description'], data));
      }

      const nextIndex = R.dec(G.ifElse(G.isZero(currentIndex), R.length(data), currentIndex));

      return setFieldValue(id, R.path([nextIndex, GC.FIELD_DESCRIPTION], data));
    }
  }, [data]);


  const handleInputChange = useCallback((value: Object, { action }: Object = {}) => {
    if (R.equals(action, 'set-value')) return clearSuggestions();

    if (G.notEquals(action, 'input-change')) return;

    setValue(value);
    setFieldValue(id, value);
  }, [clearSuggestions]);

  useEffect(() => {
    if (G.isNotNilAndNotEmpty(data)) {
      ref.current.setState((prev: Object) => ({ ...prev, focusedOption: null }));
    }
  }, [data]);

  const menuProps = G.ifElse(
    useMenuPortalTarget,
    {
      menuPlacement: 'auto',
      menuPortalTarget: document.body,
      menuShouldScrollIntoView: false,
    },
  );

  const selectProps = {
    ...menuProps,
    id,
    styles,
    components,
    options: data,
    onBlur: handleBlur,
    isDisabled: disabled,
    onFocus: handleFocus,
    isLoading: R.not(ready),
    filterOption: () => true,
    onKeyDown: handleKeyDown,
    inputValue: R.or(value, ''),
    noOptionsMessage: () => null,
    controlShouldRenderValue: false,
    onInputChange: handleInputChange,
    placeholder: R.or(placeholder, ''),
    getOptionValue: R.prop(GC.FIELD_DESCRIPTION),
    getOptionLabel: R.prop(GC.FIELD_DESCRIPTION),
    onChange: (option: Object) => handleSelect(R.prop(GC.FIELD_DESCRIPTION, option)),
  };

  return <Select {...selectProps} ref={ref} />;
};
